import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt, FaClock } from 'react-icons/fa';

const ScheduleMessage = () => {
  const [scheduling, setScheduling] = useState('immediately');
  const [dateTime, setDateTime] = useState(new Date());

  const handleDateTimeChange = (date) => {
    setDateTime(date);
  };

  return (
    <div className="tw-p-6 tw-bg-white tw-rounded-lg tw-shadow-md">
      <h3 className="tw-text-xl tw-font-semibold tw-mb-4 tw-text-gray-800">Scheduling</h3>
      <h4 className="tw-text-lg tw-font-medium tw-mb-3 tw-text-gray-700">Start sending</h4>
      <div className="tw-space-y-2 tw-mb-6">
        <label className="tw-flex tw-items-center tw-cursor-pointer">
          <input
            type="radio"
            name="scheduling"
            value="immediately"
            checked={scheduling === 'immediately'}
            onChange={() => setScheduling('immediately')}
            className="tw-mr-3 tw-text-blue-500 focus:tw-ring-blue-400"
          />
          <span className="tw-text-gray-700">Immediately</span>
        </label>
        <label className="tw-flex tw-items-center tw-cursor-pointer">
          <input
            type="radio"
            name="scheduling"
            value="customDate"
            checked={scheduling === 'customDate'}
            onChange={() => setScheduling('customDate')}
            className="tw-mr-3 tw-text-blue-500 focus:tw-ring-blue-400"
          />
          <span className="tw-text-gray-700">Custom date</span>
        </label>
      </div>
      
      {scheduling === 'customDate' && (
        <div className="tw-mb-6">
          <DatePicker
            selected={dateTime}
            onChange={handleDateTimeChange}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            className=""
            customInput={
              <div className="tw-flex tw-items-center tw-border tw-border-gray-300 tw-rounded-md tw-px-4 tw-py-2 tw-cursor-pointer tw-bg-white tw-text-gray-700 hover:tw-border-blue-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500">
                <FaCalendarAlt className="tw-mr-3 tw-text-gray-400" />
                <span>{dateTime.toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' })}</span>
                <FaClock className="tw-ml-3 tw-text-gray-400" />
              </div>
            }
          />
        </div>
      )}
      
      <p className="tw-text-sm tw-text-yellow-600 tw-mt-2">
        The time shown above is based on local time zone
      </p>
    </div>
  );
};

export default ScheduleMessage;