import React from 'react';

const SetMessageLive = () => {
    return (
      <div className="tw-p-4">
        <h3 className="tw-text-xl tw-font-bold tw-mb-4">Are you sure you are ready?</h3>
        <p className="tw-text-gray-700 tw-mb-2">Number of users</p>
        <p className="tw-text-gray-600 tw-mb-4">
          Please make sure you have confirmed all the details before hitting the button. Once started, a campaign cannot be stopped midway and this might lead to unnecessary expenses at your end.
        </p>
        <p className="tw-text-blue-600 tw-mb-6">
          You can also monitor the approximate WhatsApp charges for the month here
        </p>
        <div className="tw-flex tw-space-x-4">
          <button className="tw-bg-gray-300 tw-text-gray-700 tw-px-6 tw-py-2 tw-rounded">
            Set live
          </button>
          <button className="tw-border tw-border-blue-600 tw-text-blue-600 tw-px-6 tw-py-2 tw-rounded">
            Save as Draft
          </button>
        </div>
      </div>
    );
  };

  export default SetMessageLive