import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './WhatsAppTemplates.css';
import WATemplateLibrary from './component/WATemplateLibrary';
import WAActive from './component/WAActive';
import { Link } from 'react-router-dom';



function WhatsAppTemplates() {
  // Define state for selectedIndex
  const [selectedIndex, setSelectedIndex] = useState(0);

  // Define function to handle tab selection
  const handleTabSelect = (index) => {
    setSelectedIndex(index);
  };

  return (
    <>
      <section className='WhatsAppTemplates'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">WhatsApp Templates</h1>
            </div>
            <div class="col-md-auto text-end"><Link class="btn btn-primary add-new-program-btn" to="/create-new-template">Create New Template</Link></div>
          </div>

          <div className='reports-programs mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/8lv9k/3jq47sc8s4.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-3">Provides admins with an overview of program and condition-specific data, including key performance indicators (KPIs) on member engagement and service utilization.</p>
              </div>
            </div>
          </div>

          <div className='whatsapp-templates'>
            <div className="row">
              <div className="col-md-12">
                <Tabs selectedIndex={selectedIndex} onSelect={handleTabSelect}>
                  <TabList>
                    <Tab>Template Library</Tab>
                    <Tab>Active</Tab>
                    <Tab>Deleted</Tab>
                  </TabList>

                  <TabPanel>
                    <WATemplateLibrary/>
                  </TabPanel>
                  <TabPanel>
                    <div className="table-responsive">
                        <WAActive/>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="table-responsive">
                      Content for Deleted tab
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhatsAppTemplates;
