import React,{useState, useEffect} from 'react';

import DataTable from 'react-data-table-component';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { exportTableData, LoaderLoader } from '../../utils/CommonLibrary';
import { getCareNavigatorsDetails } from '../../../services/medengage/Service';

function DetailedCareNavigatorPerformanceTable(props) {

    const [careNavigatorList, setCareNavigatorList] = useState([])
    const [isNewLoading, setIsNewLoading] = useState(false);


    useEffect(() => {
        fetchCarenavigatorList();
    }, [props.startDate,props.endDate,props.programId,props.conditionId]);


    const fetchCarenavigatorList = async () => {
        setIsNewLoading(true);
        try {
            const response = await getCareNavigatorsDetails(props.startDate,props.endDate,props.programId,props.conditionId);
            if (response.data.code === 200) {
                getCareNavigatorsDetails(props.startDate,props.endDate,props.programId,props.conditionId).
                then((response) => {
                    setCareNavigatorList(response.data.data.users);
                    setIsNewLoading(false);
            });
                setIsNewLoading(false);
            }
        } catch (error) {
            setIsNewLoading(false);
        }
    }

    const renderCNP8Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Name of the care navigator.
        </Tooltip>
    );

    const renderCNP9Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Email of the care navigator.
        </Tooltip>
    );

    const renderCNP10Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Members Enrolled.
        </Tooltip>
    );

    const renderCNP39Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Onboarded Onboarded.
        </Tooltip>
    );

    const renderCNP11Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Total Appointments Booked.
        </Tooltip>
    );

    const renderCNP12Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Succesful Appointments.
        </Tooltip>
    );

    const renderCNP13Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Missed Appointments.
        </Tooltip>
    );

    const renderCNP14Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Average Call Duration.
        </Tooltip>
    );

    const renderCNP15Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           NPS Score.
        </Tooltip>
    );

    const renderCNP16Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Appointments Ratings.
        </Tooltip>
    );

    const renderCNP17Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Transactions.
        </Tooltip>
    );

    const renderCNP18Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Lab Referrals.
        </Tooltip>
    );

    const renderCNP19Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Home Care Referrals.
        </Tooltip>
    );

    const renderCNP20Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Medicatio Referrals.
        </Tooltip>
    );

    const renderCNP21Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Telehealth Referrals.
        </Tooltip>
    );

    const renderCNP22Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Member No Show.
        </Tooltip>
    );

    const renderCNP23Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Lab Referrals Completed.
        </Tooltip>
    );

    const renderCNP24Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Home Care Referrals Completed.
        </Tooltip>
    );

    const renderCNP25Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Medicatio Referrals Completed.
        </Tooltip>
    );

    const renderCNP26Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Telehealth Referrals Completed.
        </Tooltip>
    );


    const renderCNP27Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Initial Well Controlled.
        </Tooltip>
    );

    const renderCNP28Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Initial Well to Well Controlled.
        </Tooltip>
    );

    const renderCNP29Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Initial Well to Intermittent Poorly Controlled.
        </Tooltip>
    );

    const renderCNP30Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Initial Well to Persistent Poorly Controlled.
        </Tooltip>
    );

    const renderCNP31Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Initial Intermittent Poorly Controlled.
        </Tooltip>
    );

    const renderCNP32Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Initial Intermittent to Well Controlled.
        </Tooltip>
    );

    const renderCNP33Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Initial Intermittent to Intermittent Poorly Controlled.
        </Tooltip>
    );

    const renderCNP34Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Initial Intermittent to Persistent Poorly Controlled.
        </Tooltip>
    );


    const renderCNP35Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Initial Persistent Poorly Controlled.
        </Tooltip>
    );

    const renderCNP36Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Initial Persistent to Well Controlled.
        </Tooltip>
    );

    const renderCNP37Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Current Intermittent Poorly Controlled.
        </Tooltip>
    );

    const renderCNP38Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Initial Persistent to Persistent Poorly Controlled.
        </Tooltip>
    );

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('All');

    const columns = [
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP8Tooltip}>
                    <span>Care Navigator Name</span>
                </OverlayTrigger>
            ),
            selector: row => row.userName,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP9Tooltip}>
                    <span>Care Navigator Email</span>
                </OverlayTrigger>
            ),
            selector: row => row.email,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP39Tooltip}>
                    <span>Members Onboarded</span>
                </OverlayTrigger>
            ),
            selector: row => row.totalMembers,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP10Tooltip}>
                    <span>Members Enrolled</span>
                </OverlayTrigger>
            ),
            selector: row => row.enrollMembers,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP11Tooltip}>
                    <span>Total Appointments Booked</span>
                </OverlayTrigger>
            ),
            selector: row => row.totalAppointments,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP12Tooltip}>
                    <span>Succesful Appointments</span>
                </OverlayTrigger>
            ),
            selector: row =>row.succesfulAppointments,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP13Tooltip}>
                    <span>Missed Appointments</span>
                </OverlayTrigger>
            ),
            selector: row => row.missedAppointments,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP22Tooltip}>
                    <span>Member No Show</span>
                </OverlayTrigger>
            ),
            selector: row => row.noShow,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP14Tooltip}>
                    <span>Average Call Duration</span>
                </OverlayTrigger>
            ),
            selector: row => row.callDuration,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP15Tooltip}>
                    <span>NPS Score</span>
                </OverlayTrigger>
            ),
            selector: row => row.npsScore,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP16Tooltip}>
                    <span>Appointments Ratings</span>
                </OverlayTrigger>
            ),
            selector: row => row.appointmentsRatings,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP17Tooltip}>
                    <span>Transactions</span>
                </OverlayTrigger>
            ),
            selector: row => row.transactions,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP18Tooltip}>
                    <span>Lab Referrals</span>
                </OverlayTrigger>
            ),
            selector: row => row.lab,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP23Tooltip}>
                    <span>Lab Referrals Completed</span>
                </OverlayTrigger>
            ),
            selector: row => row.labComp,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP19Tooltip}>
                    <span>Home Care Referrals</span>
                </OverlayTrigger>
            ),
            selector: row => row.homeCare,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP24Tooltip}>
                    <span>Home Care Referrals Completed</span>
                </OverlayTrigger>
            ),
            selector: row => row.homeCareComp,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP20Tooltip}>
                    <span>Medication Referrals</span>
                </OverlayTrigger>
            ),
            selector: row => row.medication,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP25Tooltip}>
                    <span>Medication Referrals Completed</span>
                </OverlayTrigger>
            ),
            selector: row => row.medicationComp,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP21Tooltip}>
                    <span>Telehealth Referrals</span>
                </OverlayTrigger>
            ),
            selector: row => row.telehealth,
            sortable: true,
        },  
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP26Tooltip}>
                    <span>Telehealth Referrals Completed</span>
                </OverlayTrigger>
            ),
            selector: row => row.telehealthComp,
            sortable: true,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP27Tooltip}>
                    <span>Initial Well Controlled</span>
                </OverlayTrigger>
            ),
            selector: row => row.initialWellControlled,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP28Tooltip}>
                    <span>Initial Well to Well Controlled</span>
                </OverlayTrigger>
            ),
            selector: row => row.currentOneWellControlled,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP29Tooltip}>
                    <span>Initial Well to Intermittent Poorly Controlled</span>
                </OverlayTrigger>
            ),
            selector: row => row.currentOneIntermittentPoorlyControlled,
            sortable: true,
        },  
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP30Tooltip}>
                    <span>Initial Well to Persistent Poorly Controlled</span>
                </OverlayTrigger>
            ),
            selector: row => row.currentOnePersistentPoorlyControlled,
            sortable: true,
        },


        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP31Tooltip}>
                    <span>Initial Intermittent Poorly Controlled</span>
                </OverlayTrigger>
            ),
            selector: row => row.initialIntermittentPoorlyControlled,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP32Tooltip}>
                    <span>Initial Intermittent to Well Controlled</span>
                </OverlayTrigger>
            ),
            selector: row => row.currentTwoWellControlled,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP33Tooltip}>
                    <span>Initial Intermittent to Intermittent Poorly Controlled</span>
                </OverlayTrigger>
            ),
            selector: row => row.currentTwoIntermittentPoorlyControlled,
            sortable: true,
        },  
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP34Tooltip}>
                    <span>Initial Intermittent to Persistent Poorly Controlled</span>
                </OverlayTrigger>
            ),
            selector: row => row.currentTwoPersistentPoorlyControlled,
            sortable: true,
        },


        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP35Tooltip}>
                    <span>Initial Persistent Poorly Controlled</span>
                </OverlayTrigger>
            ),
            selector: row => row.initialPersistentPoorlyControlled,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP36Tooltip}>
                    <span>Initial Persistent to Well Controlled</span>
                </OverlayTrigger>
            ),
            selector: row => row.currentThrWellControlled,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP37Tooltip}>
                    <span>Initial Persistent to Intermittent Poorly Controlled</span>
                </OverlayTrigger>
            ),
            selector: row => row.currentThrIntermittentPoorlyControlled,
            sortable: true,
        },  
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderCNP38Tooltip}>
                    <span>Initial Persistent to Persistent Poorly Controlled</span>
                </OverlayTrigger>
            ),
            selector: row => row.currentThrPersistentPoorlyControlled,
            sortable: true,
        },
    ];

    
    

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedStatus('All');
    };

    const filteredData = careNavigatorList.filter(user => {
        const matchesSearchQuery =
            (typeof user.userName === 'string' && user.userName.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (typeof user.email === 'string' && user.email.toString().includes(searchQuery));
        return matchesSearchQuery ;
    });


    const handleExport = () => {

        const modifiedData = filteredData.map(item => {
            return {
                'Care Navigator Name': item.userName,
                'Care Navigator Email': item.email,
                'Members Onboarded': item.totalMembers,
                'Members Enrolled': item.enrollMembers,
                'Total Appointments Booked' : item.totalAppointments,
                'Succesful Appointments' : item.succesfulAppointments,
                'Missed Appointments' : item.missedAppointments,
                'Member No Show' : item.noShow,
                'Average Call Duration' : item.callDuration,
                'NPS Score' : item.npsScore,
                'Appointments Ratings' : item.appointmentsRatings,
                'Transactions' : item.transactions,
                'Lab Referrals' : item.lab,
                'Lab Referrals Completed' : item.labComp,
                'Home Care Referrals' : item.homeCare,
                'Home Care Referrals Completed' : item.homeCareComp,
                'Medication Referrals' : item.medication,
                'Medication Referrals Completed' : item.medicationComp,
                'Telehealth Referrals' : item.telehealth,
                'Telehealth Referrals Completed' : item.telehealthComp,
                'Initial Well Controlled' : item.initialWellControlled,
                'Initial Well to Well Controlled' : item.currentOneWellControlled,
                'Initial Well to Intermittent Poorly Controlled' : item.currentOneIntermittentPoorlyControlled,
                'Initial Well to Persistent Poorly Controlled' : item.currentOnePersistentPoorlyControlled,
                'Initial Intermittent Poorly Controlled' : item.initialIntermittentPoorlyControlled,	
                'Initial Intermittent to Well Controlled' : item.currentTwoWellControlled,
                'Initial Intermittent to Intermittent Poorly Controlled' : item.currentTwoIntermittentPoorlyControlled,
                'Initial Intermittent to Persistent Poorly Controlled' : item.currentTwoPersistentPoorlyControlled,
                'Initial Persistent Poorly Controlled' : item.initialPersistentPoorlyControlled,
                'Initial Persistent to Well Controlled' : item.currentThrWellControlled,
                'Initial Persistent to Intermittent Poorly Controlled' : item.currentThrIntermittentPoorlyControlled,
                'Initial Persistent to Persistent Poorly Controlled' : item.currentThrPersistentPoorlyControlled
            }});
    
        modifiedData.forEach(function(v){ delete v.id; });

        exportTableData("CareNavigatorDetails",modifiedData);
    };

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                <div className="col-3 mt-2"><h1 className='dashboard-graph-title'>Detailed Care Navigator Performance</h1></div>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search by Care Navigator Name or Email"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleExport} className="btn btn-primary btn-primary clear-search-btn"><i className="bi bi-download"></i> Export</button>
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                customStyles={customStyles}
            />
            {isNewLoading && <LoaderLoader /> }
        </>
    );
}

export default DetailedCareNavigatorPerformanceTable;
