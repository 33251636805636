import React from 'react';
import './CareNavigatorPage.css';
import ContentCareNavigator from './componenents/ContentCareNavigator/ContentCareNavigator';


function CareNavigatorPage() {


  return (
    <>
        <ContentCareNavigator/>
    </>
  );
}

export default CareNavigatorPage;
