import React from 'react';
import './MemberProfile.css';
import TimelineProfile from './TimelineProfile';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function MemberProfile() {
  const memberBasicInfo = {
    memberName: "Azma Nadaf",
    city: "Jeddah, Saudi Arabia",
    gender: "F",
    age: 30,
    height: 175,
    weight: 70,
    bmi: 22.9,
    hba1c: 5.5,
    email: "john.doe@example.com",
    mobile: "+966 958743126",
    altMobile: "+966 958743126",
    readonly: "N",
    membershipNumber: "33390425",
    carenavigatorName: "N sob sobale",
    mainMembershipNumber: "33390425",
    policyMemberType: "Employee",
    policyStartDate: "25 May, 2024",
    policyEndDate: "25 May, 2025",
    contractNumber: "46889700",
    contractName: "SAUDI ARAMCO TOTAL REFINING & PETROCHEMICAL Co. (SATORP)"
  };

  const handleShowMemberProfileDetailsModal = () => {
    // Implement modal logic here
  };

  return (
    <>
      {/* Uncomment Navbar if needed */}
      {/* <Navbar /> */}
      <div id="content-wrapper" className="ContentDashboard">
        <div className=" mb-3">
          <div className="container-fluid ">
            <div className='row'>
              <div className='col-md-12 mt-3'>
                <div className="profile-card-new">
                  <div className='row'>
                    {/* <div className='col-md-auto'>
                      <div className="profile-image-1">
                        <img src="https://appcdn.goqii.com/user/user/l_1437644704.png" alt="Profile Image" />
                      </div>
                    </div> */}
                    <div className='col-md'>
                      <div className='row'>
                        <div className='col-md'>
                          <div className="profile-info">
                            <div className="info-line">
                              <div className="info">
                                <a onClick={handleShowMemberProfileDetailsModal} className="name">{memberBasicInfo.memberName}</a>
                                <span className="location"><i className="fa fa-map-marker" aria-hidden="true"></i>
                                  {memberBasicInfo.city}</span>
                              </div>
                            </div>

                            <div className="info-line">
                              <div className="info">
                                <span className="gender big-text me-3">
                                  <i className="" aria-hidden="true">
                                    <img src={memberBasicInfo.gender === "M" ? "https://storage.googleapis.com/ksabupatest/2024/09/24/5shux/73lal8g48h.png" : "https://storage.googleapis.com/ksabupatest/2024/09/24/hhqe5/gl8hw4hhl9.png"} style={{ height: "18px" }} alt="" />
                                  </i> {memberBasicInfo.gender === "M" ? "Male" : "Female"} {memberBasicInfo.age}
                                </span>
                                <span className="physical big-text me-3">
                                  <i className=""><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/8u7jd/njt3shm0c4.png" style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.height} cms
                                </span>
                                <span className="physical big-text me-3">
                                  <i className=""><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/f4jhy/qhl8vj7lx4.png" style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.weight} kgs
                                </span>
                                <span className="physical big-text">
                                  <i className=""><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/zetj7/b4loqjyhqa.png" style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.bmi}
                                </span>
                                <span className="physical big-text">
                                  <i className=""><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/qem1c/m9x870b478.png" style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.hba1c}
                                </span>
                              </div>
                            </div>
                            
                            <div className="info-line">
                              <div className="info">
                                <span className="email"><i className="fa fa-envelope pad-right-10" aria-hidden="true"></i>{memberBasicInfo.email}</span>
                                <span className="phone"><i className="fa fa-phone pad-right-10" aria-hidden="true"></i>
                                  {memberBasicInfo.mobile}</span>
                                <span className="phone"><i className="fa fa-phone pad-right-10" aria-hidden="true"></i>
                                  {memberBasicInfo.altMobile ? memberBasicInfo.altMobile : "-"}</span>
                                
                              </div>
                            </div>
                            <hr className='my-3' />
                            <div className="info-line">
                              <div className="info row">
                                <h5 className="membership-id col-3 mb-2"><b>Membership:</b> {memberBasicInfo.membershipNumber}</h5>
                                <h5 className="membership-id col-3 mb-2"><b>Care Navigator:</b> {memberBasicInfo.carenavigatorName ? memberBasicInfo.carenavigatorName : "-"}</h5>
                                <h5 className="membership-id col-6 mb-2"><b>Contract No:</b> {memberBasicInfo.contractNumber}</h5>
                              </div>
                            </div>
                            <div className="info-line">
                              <div className="info row">
                                <h5 className="membership-id col-3 mb-2"><b>Main Membership:</b> {memberBasicInfo.mainMembershipNumber}</h5>
                                <h5 className="membership-id col-3 mb-2"><b>Membership Type:</b> {memberBasicInfo.policyMemberType ? memberBasicInfo.policyMemberType : "-"}</h5>
                                <h5 className="dates col-6 mb-2"><b>Contract Name:</b> {memberBasicInfo.contractName}</h5>
                              </div>
                            </div>
                            <div className="info-line">
                              <div className="info row">
                                <h5 className="dates col-3 mb-2"><b>Policy Start Date:</b> {memberBasicInfo.policyStartDate} </h5>
                                <h5 className="dates col-3 mb-2"><b>Policy End Date:</b> {memberBasicInfo.policyEndDate}</h5>
                              </div>
                            </div>
                            {/* <div className="info-line">
                              <div className="info row">
                                <h5 className="membership-id col-3 mb-2"><b>Contract No:</b> {memberBasicInfo.contractNumber}</h5>
                                <h5 className="dates col-3 mb-2"><b>Contract Name:</b> {memberBasicInfo.contractName}</h5>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 my-3'>
              <div className='comntent-MemberDeEnrollment profile-card-timeline mb-3'>
                <div className="row">
                    <div className="col-md-12">
                    <Tabs>
                        <TabList>
                        <Tab>Button 1</Tab>
                        <Tab>Button 2</Tab>
                        </TabList>

                        <TabPanel>
                        <div className='profile-card-timeline'>
                          <TimelineProfile/>
                        </div>
                        </TabPanel>

                        <TabPanel>
                          2
                        </TabPanel>
                    </Tabs>
                    
                    </div>
                </div>
                </div>
                      
                   
                      
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MemberProfile;
