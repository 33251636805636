import React, { useState, useEffect,useRef } from "react";
import { Link } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import {
    add_new_services,
  add_new_services_data,
  fetch_pathway_frequency_data,
  fetch_program_dashboard_summery_list,
  fetch_program_data,
  fetch_service_data,
  fetchLinkUserDataService,
  frequency_service_update,
} from "../../../services/mtp/Service";
import {
  LoaderLoader,
  SelectProgram,
  formatDate,
} from "../../utils/CommonLibrary";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
import { useParams } from "react-router-dom";

function ContentServiceList() {
  const [pathwayName, setPathwayName] = useState("");
  const [pathwayPopupData, setPathwayPopupData] = useState([]);
  const [servicePopupData, setServicePopupData] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedServicesIds, setSelectedServicesIds] = useState([]);
  const [itemData, setItemData] = useState([]); 
  const [selectedMonth, setSelectedMonth] = useState(0); 
  const [searchTerm, setSearchTerm] = useState(""); // Add search term state
  const [filteredServiceData, setFilteredServiceData] = useState([]); // Add filtered service data
  const [showUserConfirmModal, setShowUserConfirmModal] = useState(false); //confirmation box
  const modalCloseRef = useRef();
  
  const {pathwayId}  = useParams(); 
  const [months, setAllMonths] = useState([
    '1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th' , '9th', '10th', '11th', '12th'
  ]);
  const [frequencyId, setFrequencyId] = useState('');

  const [formData, setFormData] = useState({
    serviceName: ''
  });
  const [errors, setErrors] = useState({
    serviceName: ''
  }); 
  
const [checkboxErrors, setheckboxErrors] = useState(''); 
const [addServiceMessage,setAddServiceMessage] = useState('');
const [freqServiceMessage,setFreqServiceMessage] = useState('');
const [originalSelectedServiceIds, setOriginalSelectedServiceIds] = useState([]); 
const [userLinkData, setUserLinkData] = useState([]);
const [checkedService, setCheckedService] = useState(true);



  const pathwayModelPopup = async () => {
    try {
        setIsLoading(true); //to show loader
        let response = await fetch_pathway_frequency_data(pathwayId);
        if (response.data.code === 200) {
          console.log("response",response.data.data);
            setPathwayName(response.data.pathwayName);
            console.log("response.data.data",response.data.data);
            setPathwayPopupData(response.data.data);
            setServicePopupData(response.data.data[0].serviceData);
            setOriginalSelectedServiceIds(response.data.data[0].serviceData); 
        }
        setIsLoading(false); //to hide loader
    } catch (error) {
      console.error(error);
    }
  };

  const servicesModelPopup = async (frequencyId) => {
    try {
         fetchServiceData();
        } catch (error) {
        console.error(error);
        }
  };

  useEffect(() => {
    fetchProgramData();
    fetchServiceData();
    pathwayModelPopup(); 
  }, []);

  // To get program list from master
  const fetchProgramData = async () => {
    try {
      let resp = await fetch_program_data();
      setIsLoading(true); //to show loader
      if (resp.data.code === 200) {
        setProgramData(resp.data.data);
      }
      setIsLoading(false); //hide loader
    } catch (error) {
      console.error(error);
    }
  };

  //To get services list from master
  const fetchServiceData = async () => {
    try {
      setIsLoading(true); //to show loader
      let result = await fetch_service_data();
      if (result.data.code === 200) {
        setServiceData(result.data.data);
        setFilteredServiceData(result.data.data); //Initialize filtered data
      }
      setIsLoading(false); //hide loader
    } catch (error) {
      console.error(error);
    }
  };

 //Add new service form hanlder
  const handleServiceChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  //Add new service in master
const handleSubmit = async (e)=>{
    try {
        e.preventDefault();
        const newErrors = {};
        console.log("formData['serviceName']",formData['serviceName']);
        if (formData['serviceName'].trim().length === 0) {
          newErrors.serviceName = 'service name can not blank';
          setAddServiceMessage('');
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setTimeout(() =>{ 
                setErrors({
                  serviceName:''
                });              
              }, 2000);
            return;

        }else{
                setIsLoading(true); //to show loader
                let result = await add_new_services(formData);
                if (result.data.code === 200) {
                    setAddServiceMessage(result.data.data.message);
                    setErrors({serviceName:''});
                    setFormData({serviceName:''});
                    fetchServiceData();
                }else{
                    setAddServiceMessage(result.data.data.message);
                }
                setIsLoading(false); //hide loader
            }
    } catch (error) {
        console.error(error);
    }
}

 // Handle search input change
 const handleSearchChange = (e) => {
  const searchValue = e.target.value.toLowerCase();
  setSearchTerm(searchValue);
  const filteredData = serviceData.filter(service =>
    service.serviceName.toLowerCase().includes(searchValue)
  );
  setFilteredServiceData(filteredData);
  
};

  // extracting the ids of the selected service

  function selectSelectedServices(item,month){ 

    console.log('log',item);
    setSearchTerm('');
    //handleSearchChange();
    setSelectedMonth(month);
    setItemData(item);
    item = item || {};
    const customServiceId = item.serviceId || "";
    if(item !== ""){
    const servicesArray = customServiceId.split(" | ");
    const servicesJson = JSON.stringify(servicesArray);
    console.log("servicesJson",servicesArray[0]);
    //  servicesJson.forEach((serviceId) =>{ 
    //   console.log("service1",serviceId);
    //     serviceIdArray.push(serviceId); 
    //  }); 
    const numberArray = servicesArray[0].split(",").map(Number)
    setSelectedServicesIds(numberArray);
    console.log("confirm services",selectedServicesIds)
  }
}

  // on change for the 
  // function onServiceChange(e){ 
  //   setCheckedService(false);
  //   if(e.target.checked){ 
  //       setSelectedServicesIds((previosuIds) => [...previosuIds, parseInt(e.target.value)])
  //       setCheckedService(true);
  //   }else{ 
  //       setSelectedServicesIds((previousIds) => previousIds.filter((id) => id !== parseInt(e.target.value)))
  //   }
  //   saveServiceUpdate(e.target.value);
  // }

  //isuse fixed
  function onServiceChange(e) {
    setCheckedService((prevState) => !prevState);  // Toggle the checked state
  
    if (e.target.checked) {
      // Add the service ID if checked
      setSelectedServicesIds((previousIds) => [...previousIds, parseInt(e.target.value)]);
    } else {
      // Remove the service ID if unchecked
      setSelectedServicesIds((previousIds) => previousIds.filter((id) => id !== parseInt(e.target.value))
      );
    }
  
    saveServiceUpdate(e.target.value);
  }
  
const saveServiceUpdate = async (passServiceId)=>{

  //let passServiceId = selectedServicesIds.filter(element => element !== 0);
  let data = {
      pathwayId: pathwayId,
      selectedServicesIds:passServiceId,
      month:selectedMonth,
      checked:checkedService
  }
  console.log("data",JSON.stringify(data));
  setIsLoading(true); //to show loader
  fetchLinkUserDataService(JSON.stringify(data))
  .then((response) => {   
      setUserLinkData(response.data.data.linkUsersMessage);
      setIsLoading(false); 
      if(response.data.data.infoBoxShow && checkedService){
        setShowUserConfirmModal(true);
      }
      
  });
  
 
  return; 

}

const serviceUpdate =async () =>{
    try{
    //    if(selectedServicesIds.length === 0){
    //     setheckboxErrors("Please at least on service");
    //    }else{
      let passServiceId = selectedServicesIds.filter(element => element !== 0);
        let data = {
            pathwayId: pathwayId,
            selectedServicesIds:passServiceId,
            month:selectedMonth
        }
       
       // checkboxErrors
        setIsLoading(true); //to show loader
        let result = await frequency_service_update(JSON.stringify(data));
       
        if (result.data.code === 200) {
            setFreqServiceMessage(result.data.data.message);
            pathwayModelPopup();
            fetchServiceData();
            setTimeout(() => { 
                modalCloseRef.current.click();
                setFreqServiceMessage('');
                //setSearchTerm('');
              }, 1000);
        }
        setIsLoading(false); //hide loader
       //}
    }catch (error) {
        console.error(error);
    }
    
}

const handleConfirmClose =()=>{
  setShowUserConfirmModal(false); 
}
const handleCloseModal =()=>{
  fetchServiceData();
}

  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <>
    <section className="ContentPrograms">

    
      <div className="container-fluid px-0 mb-3">
        <div className="content-programs mb-3">
          <div className="row align-items-center">
            <LoaderLoader isLoading={isLoading} />
            <div className="col-auto mt-2 ps-4">
                            <h3 className="btn btn-primary back-btn mb-0" onClick={handleBackClick}><i class="bi bi-chevron-left me-1"></i> Back</h3>
            </div>
            <div className="col mt-2 px-3">
              <h3 className="main-title mb-0">{pathwayName}</h3>
            </div>
            {/* <div className="col-auto mt-2 px-4">
              <a
                data-bs-toggle="modal"
                data-bs-target="#upcomingFeatureModal"
                className="btn btn-primary devision-add-btn"
                href="/programs"
              >
                <i className="bi bi-plus-circle me-2"></i> Add
              </a>
            </div> */}
          </div>

          <div className="row mt-2 px-4">
            <div className="col-md-12 px-0">
              <div className="table-responsive pathways-new-table">
                <table className="table table-striped mb-0 mt-2">
                  <tbody>

                  {

                    months.map((item, index) => (
                        <tr>
                            <td key={index}>
                                <h5 className="main-pathways-title">{item} Month</h5>
                            </td>

                           
                            <td>
                            {pathwayPopupData.filter((data) => data.month === item).length === 0 ? (
                                '-'
                            ) : (
                                pathwayPopupData
                                    .filter((data) => data.month === item)
                                    .map((data, dataIndex) => (
                                        <div key={dataIndex}>{data.servicesName}</div>
                                    ))
                            )}
                        </td>
                                {/* {
                                pathwayPopupData.filter((data) => data.month === item)[0].serviceData.map((option, index) => ( 
                                    
                                        <span key={index}>
                                          {option.serviceName} {option.frequencyId}
                                        </span>
                                    
                                ))
                            } */}
                            
                               
                            <td className="text-end">
                                <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#pathwaysProgramsModal"
                                    style={{with:'100px'}}
                                    aria-label="Update Services" title="Update Services"
                                    href="/programs"
                                    onClick={e =>  selectSelectedServices(
                                        pathwayPopupData.filter((data) => data.month === item).length === 0 ? {}:   pathwayPopupData.filter((data) => data.month === item)[0],item
                                    )}
                                >
                                <i className="bi bi-pencil me-2" ></i>
                                </a>
                            </td>
                        
                        </tr>
                    ))
                  }

                 
                                                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      </section>
      <div
        className="modal fade"
        id="pathwaysModal"
        tabindex="1"
        aria-labelledby="pathwaysModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-subtitle">Duration</h4>
              <h1 className="modal-title">{pathwayName}</h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  {pathwayPopupData.map((item, index) => (
                    <>
                      <h3 className="text-start pathways-box" key={index}>
                        {item.month} Month
                      </h3>
                      <div className="row px-2 mb-3">
                        {/* {item.serviceData.map((option, index) => (
                          <>
                            <div className="col-md-6 my-2" key={index}>
                              <img
                                src="https://storage.googleapis.com/ksabupatest/2024/09/24/tsslj/j461v4ma4e.png"
                                className="table-icon"
                                alt=""
                              />
                              {option.serviceName}
                            </div>
                          </>
                        ))} */}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="upcomingFeatureModal"
        tabindex="1"
        aria-labelledby="upcomingFeatureModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header mb-4">
              <h4 className="modal-subtitle">Available in Phase 2!</h4>
              <h1 className="modal-title">Upcoming Feature:</h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="pathwaysProgramsModal"
        tabindex="1"
        aria-labelledby="pathwaysProgramsModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">Update Services - {selectedMonth} Month</h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={modalCloseRef}
                onClick={handleCloseModal}
              ></button>
              
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                <input
                    type="text"
                    className="form-control input-field-ui mb-3"
                    placeholder="Search Services..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{marginTop:"2px"}}
                  />
                </div>
                <div className="col-md-12 px-4">
                  {filteredServiceData.map((service, index) => (
                    <div className="form-check mb-3 d-flex">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={service.serviceId}
                        id={service.serviceName}
                        checked={selectedServicesIds.includes(parseInt(service.serviceId))}
                        onChange={e => onServiceChange(e)}
                      
                      />
                      <label
                        className="form-check-label"
                        htmlFor={service.serviceName}
                      >
                        {service.serviceName}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="col-md-12 px-4 mb-3">
                {/* <LoaderLoader isLoading={isLoading} /> */}
                {/* <form onSubmit={handleSubmit}>
                                <textarea className="form-control input-field-ui mb-1" id="exampleFormControlTextarea1" rows="2" name="serviceName"  value={formData.serviceName}
                                  onChange={handleServiceChange} placeholder="Add new service here "></textarea>
                                  {errors.serviceName && <span className="text-danger">{errors.serviceName}<br/></span>}
                                  {addServiceMessage && <span className="text-success">{addServiceMessage}<br/></span>}
          
                                <button type="submit" class="btn btn-primary services-add-btn"><i class="bi bi-plus-circle me-2"></i> Add New Service</button>
                                </form> */}
                </div>

               
              </div>
            </div>
            <div class="modal-footer">
            <div className="col-md-12 px-4 my-2">
                {/* {freqServiceMessage && <span className="text-success">{freqServiceMessage}<br/></span>} */}
                  <button
                    type="submit"
                    className="btn btn-primary program-save-btn"
                    onClick={serviceUpdate}
                    
                  >
                    Save
                  </button>{" "}
                  {/* <button type="submit" className="btn btn-primary clear-btn" data-bs-dismiss="modal"
                aria-label="Close" ref={modalCloseRef}>
                  Cancel
                  </button> */}
                  
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`modal fade ${showUserConfirmModal ? 'show' : ''}`} id="showUserConfirmModalLabel" tabIndex="-1" aria-labelledby="showUserConfirmModalLabel" aria-hidden={!showUserConfirmModal} style={{display: showUserConfirmModal ? 'block' : 'none'}}>
  <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleConfirmClose}
                        ></button>
                    </div>
                    <div className="modal-body pt-1 mb-2 mx-auto w-80">
                   {userLinkData}
                    </div>
                    <div className="mx-auto w-40 mb-4">
                    {/* <button tpe="button" className="btn btn-primary program-save-btn me-2">
                        <i className="fas fa-trash-alt"></i>Confirm</button> */}
                                        {/* <button tpe="button" className="btn btn-secondary clear-btn" onClick={handleViewDeleteClose}>
                                        <i className="fas fa-trash-alt" ></i>Cancel </button> */}
                                        </div>

                        </div>
                </div>
                <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
            </div>
    </>
  );
}

export default ContentServiceList;