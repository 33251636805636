import React, { useState, useRef, useEffect } from 'react';
import { FaChevronDown, FaInfoCircle, FaUser, FaCheck, FaTimes, FaSearch } from 'react-icons/fa';

const ChooseAudience = () => {
    const [selectedTags, setSelectedTags] = useState([]);
    const [showTagDropdown, setShowTagDropdown] = useState(false);
    const [currentTag, setCurrentTag] = useState(null);
  const [showMoreTags, setShowMoreTags] = useState(false);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showTraitsModal, setShowTraitsModal] = useState(false);
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [showConditionDropdown, setShowConditionDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const tagContainerRef = useRef(null);

  const users = [
    { id: 1, color: 'tw-bg-green-400', contact: '+919772793207', email: '-', tags: '', createdOn: '27/08/2024' },
    { id: 2, color: 'tw-bg-blue-400', contact: '+919101161809', email: '-', tags: '', createdOn: '27/08/2024' },
    { id: 3, color: 'tw-bg-yellow-400', contact: '+919792527996', email: '-', tags: '', createdOn: '27/08/2024' },
    { id: 4, color: 'tw-bg-red-400', contact: '+917207318527', email: '-', tags: '', createdOn: '27/08/2024' },
    { id: 5, color: 'tw-bg-purple-400', contact: '+919915414845', email: '-', tags: '', createdOn: '27/08/2024' },
  ];

  const toggleUserCheck = (userId) => {
    setCheckedUsers(prev => 
      prev.includes(userId) 
        ? prev.filter(id => id !== userId)
        : [...prev, userId]
    );
  };

  const tags = ['All Users', 'pa 08', 'SUNNY 08', 'CHEMBUR CAMP NEW', 'mahul plus lokhande', 'Sunny'];
  const moreTags = ['PARWAGE BMC', 'PRACHI', 'SWATI', 'Combined MAHUL', 'Csv upload 10th Jul 05:16', 'BMC INVITE'];


//   const handleTagClick = (tag) => {
//     if (tag === 'All Users') {
//       setSelectedTags(['All Users']);
//     } else {
//       const newTags = selectedTags.filter(t => t !== 'All Users');
//       if (newTags.includes(tag)) {
//         setSelectedTags(newTags.filter(t => t !== tag));
//       } else {
//         setSelectedTags([...newTags, tag]);
//       }
//     }
//   };

const handleTagClick = (tag, event) => {
    if (selectedTags.some(t => t.tag === tag)) {
      // If tag is already selected, remove it
      setSelectedTags(selectedTags.filter(t => t.tag !== tag));
    } else {
      // If tag is not selected, set it as current tag and show condition dropdown
      setCurrentTag(tag);
      const rect = event.target.getBoundingClientRect();
      const containerRect = tagContainerRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom - containerRect.top,
        left: rect.left - containerRect.left
      });
      setShowConditionDropdown(true);
    }
  };

  useEffect(() => {
    if (showConditionDropdown && currentTag && tagContainerRef.current) {
      const tagElements = tagContainerRef.current.querySelectorAll('button');
      const tagElement = Array.from(tagElements).find(el => el.textContent === currentTag);
      if (tagElement) {
        const rect = tagElement.getBoundingClientRect();
        const containerRect = tagContainerRef.current.getBoundingClientRect();
        setDropdownPosition({
          top: rect.bottom - containerRect.top,
          left: rect.left - containerRect.left
        });
      }
    }
  }, [showConditionDropdown, currentTag]);

  const handleConditionSelect = (condition) => {
    setSelectedTags([...selectedTags, { tag: currentTag, condition }]);
    setShowConditionDropdown(false);
    setCurrentTag(null);
  };

  const handleRemoveTag = (tag) => {
    setSelectedTags(selectedTags.filter(t => t.tag !== tag));
  };

  const TagsInfoModal = () => (
    <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
      <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-w-full tw-max-w-3xl">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <h2 className="tw-text-xl tw-font-bold">How to add tags to users?</h2>
          <button onClick={() => setShowTagsModal(false)} className="tw-text-gray-500 hover:tw-text-gray-700">
            &times;
          </button>
        </div>
        <p className="tw-mb-4">
          Tags are identifiers for a user, which can be used to filter your campaign audience. For example: You could apply the tag 'Frequent Buyers' for all users who frequently purchase your products, and then, filter your campaign audience by this tag to send them a promotional offer.
        </p>
        <p className="tw-font-semibold tw-mb-2">There are 5 ways to apply tags to your users:</p>
        <ul className="tw-list-disc tw-pl-5 tw-space-y-2">
          <li>
            <span className="tw-font-semibold">Upload a CSV</span> - On uploading a CSV containing details of your users, a default tag (for example: Csv upload 5th Apr 11:36) is applied on all users in the CSV. You can choose to apply custom tags as well. <a href="#" className="tw-text-blue-600">Click here to upload CSV</a>
          </li>
          <li>
            <span className="tw-font-semibold">Bulk tag from the Users Page</span> - Apply audience filters in the Users page, or select the users individually and apply tags. <a href="#" className="tw-text-blue-600">Click here to go to the Users Page</a>
          </li>
          <li>
            <span className="tw-font-semibold">Tag users in the Inbox</span> - While speaking to a user in the inbox, you can easily add a tag from the right panel. <a href="#" className="tw-text-blue-600">Click here to go to Inbox</a>
          </li>
          <li>
            <span className="tw-font-semibold">Automated tags</span> - These are tags applied by us automatically basis certain actions or behavior of the users. <a href="#" className="tw-text-blue-600">Click here to configure rules for auto-tags</a>
          </li>
          <li>
            <span className="tw-font-semibold">Add tags via API</span> - Call Interakt's Track User API to add tags to a user. <a href="#" className="tw-text-blue-600">Click here to see API document</a>
          </li>
        </ul>
      </div>
    </div>
  );

  const TraitsEventsInfoModal = () => (
    <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
      <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-w-full tw-max-w-3xl">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <h2 className="tw-text-xl tw-font-bold">How to add traits & events to users?</h2>
          <button onClick={() => setShowTraitsModal(false)} className="tw-text-gray-500 hover:tw-text-gray-700">
            &times;
          </button>
        </div>
        <p className="tw-mb-4">
          <span className="tw-font-semibold">User traits</span> capture different details of your users. For example: name, city, last order date, birthday etc. A user trait can only have 1 value, which can be updated.
        </p>
        <p className="tw-mb-4">
          <span className="tw-font-semibold">Events</span> refer to external occurrences of your users. For example: the 'order placed' event signifies the placement of an order by the user or the 'Abandoned checkout' event signifies the abandonment of a checkout by the user. Further, events can have 'event traits' to describe details of the event; for example - the 'order placed' event might have 'event traits' like 'order_number', 'order_url' etc.
        </p>
        <p className="tw-font-semibold tw-mb-2">User traits can be added to your users in the following ways:</p>
        <ul className="tw-list-disc tw-pl-5 tw-space-y-2">
          <li>
            <span className="tw-font-semibold">Upload a CSV</span> - In the CSV containing details of your users, add columns for each user trait. <a href="#" className="tw-text-blue-600">Click here to upload CSV</a>
          </li>
          <li>
            <span className="tw-font-semibold">Add user traits via API</span> - Call Interakt's Track User API to add traits to a user. <a href="#" className="tw-text-blue-600">Click here to see API document</a>
          </li>
        </ul>
        <p className="tw-font-semibold tw-mt-4 tw-mb-2">Events can be added to your users in the following way:</p>
        <ul className="tw-list-disc tw-pl-5">
          <li>
            <span className="tw-font-semibold">Add events via API</span> - Call Interakt's Track Events API to add events to a user. <a href="#" className="tw-text-blue-600">Click here to see API document</a>
          </li>
        </ul>
      </div>
    </div>
  );

  const filterOptions = [
    'customer_id', 'User Id', 'Phone Number', 'Email', 'Name', 'Created On',
    'Country Code', 'row_number', 'null', 'marked_spam', 'City', 'Area'
  ];

  const conditionOptions = [
    'is', 'is not', 'contains', 'does not contain', 'is unknown', 'has any value'
  ];

  const handleAddFilter = () => {
    setShowFilterDropdown(true);
  };

  const handleSelectFilter = (filter) => {
    setCurrentFilter({ name: filter, condition: null });
    setShowFilterDropdown(false);
    setShowConditionDropdown(true);
  };

  const handleSelectCondition = (condition) => {
    setCurrentFilter({ ...currentFilter, condition });
    setShowConditionDropdown(false);
    setSelectedFilters([...selectedFilters, { ...currentFilter, condition }]);
    setCurrentFilter(null);
  };

  const handleRemoveFilter = (index) => {
    setSelectedFilters(selectedFilters.filter((_, i) => i !== index));
  };

  return (
    <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-md">
      <h3 className="tw-text-xl tw-font-semibold tw-mb-4">Select notification type</h3>
      <div className="tw-mb-4 tw-space-y-2">
        <label className="tw-flex tw-items-center">
          <input type="radio" name="notificationType" value="ongoing" className="tw-mr-2" />
          <span className="tw-text-gray-700">ongoing</span>
        </label>
        <label className="tw-flex tw-items-center">
          <input type="radio" name="notificationType" value="onetime" className="tw-mr-2" defaultChecked />
          <span className="tw-text-gray-700">onetime</span>
        </label>
      </div>
      <p className="tw-text-sm tw-text-blue-600 tw-mb-6 tw-flex tw-items-center">
        <span className="tw-mr-1">ℹ️</span> Learn more about Onetime and Ongoing Campaign
      </p>
      <h3 className="tw-text-xl tw-font-semibold tw-mb-4">Who will receive this message</h3>
      <div className="tw-border tw-border-gray-300 tw-rounded-lg tw-mb-4">
      <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-md">
      <h3 className="tw-text-xl tw-font-bold tw-mb-4">Apply Audience Filters</h3>
      
      {/* <div className="tw-mb-4">
        <div className="tw-flex tw-items-center tw-mb-2">
          <span className="tw-text-gray-700 tw-mr-2 tw-font-semibold">FILTER BY TAGS</span>
          <FaInfoCircle className="tw-text-blue-500 tw-cursor-pointer" />
        </div>
        
        <div className="tw-flex tw-flex-wrap tw-gap-2 tw-mb-2">
        {selectedTags.map((tagObj) => (
          <div key={tagObj.tag} className="tw-bg-green-100 tw-text-green-800 tw-px-3 tw-py-1 tw-rounded-full tw-text-sm tw-flex tw-items-center">
            {tagObj.condition} {tagObj.tag}
            <button
              onClick={() => handleRemoveTag(tagObj.tag)}
              className="tw-ml-2 tw-text-green-800 hover:tw-text-green-900"
            >
              <FaTimes />
            </button>
          </div>
        ))}
      </div>
        
      <div ref={tagContainerRef} className="tw-flex tw-flex-wrap tw-gap-2 tw-items-center tw-relative">
        {tags.map((tag) => (
          <button
            key={tag}
            onClick={(event) => handleTagClick(tag, event)}
            className={`tw-px-3 tw-py-1 tw-rounded-full tw-text-sm ${
              selectedTags.some(t => t.tag === tag)
                ? 'tw-bg-green-100 tw-text-green-800 tw-border tw-border-green-300'
                : 'tw-bg-gray-100 tw-text-gray-800 tw-border tw-border-gray-300'
            }`}
          >
            {tag}
          </button>
        ))}
          <div className="tw-relative">
            <div className="tw-flex tw-items-center tw-border tw-border-gray-300 tw-rounded-full tw-overflow-hidden">
              <FaSearch className="tw-text-gray-400 tw-ml-3" />
              <input
                type="text"
                placeholder="Search a Tag"
                className="tw-px-3 tw-py-1 tw-outline-none"
                onClick={() => setShowTagDropdown(true)}
              />
              <button
                onClick={() => setShowTagDropdown(!showTagDropdown)}
                className="tw-px-2 tw-py-1 tw-bg-gray-100"
              >
                <FaChevronDown className={`tw-text-gray-400 ${showTagDropdown ? 'tw-rotate-180' : ''} tw-transition-transform`} />
              </button>
            </div>
            {showTagDropdown && (
              <div className="tw-absolute tw-z-10 tw-mt-1 tw-w-56 tw-bg-white tw-rounded-md tw-shadow-lg tw-border tw-border-gray-200">
                <div className="tw-p-2 tw-flex tw-items-center tw-justify-between">
                  <label className="tw-flex tw-items-center">
                    <input type="checkbox" className="tw-mr-2" />
                    <span className="tw-text-sm">Tags</span>
                  </label>
                  <label className="tw-flex tw-items-center">
                    <input type="checkbox" className="tw-mr-2" />
                    <span className="tw-text-sm">Auto Segments</span>
                  </label>
                </div>
                <ul className="tw-max-h-60 tw-overflow-auto">
                  {moreTags.map((tag) => (
                    <li
                      key={tag}
                      className="tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-cursor-pointer tw-flex tw-justify-between tw-items-center"
                      onClick={() => handleTagClick(tag)}
                    >
                      {tag}
                      <FaChevronDown className="tw-text-gray-500" />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        
        {showConditionDropdown && currentTag && (
          <div 
            className="tw-absolute tw-z-20 tw-w-32 tw-bg-white tw-rounded-md tw-shadow-lg tw-border tw-border-gray-200"
            style={{ top: `${dropdownPosition.top}px`, left: `${dropdownPosition.left}px` }}
          >
            <ul>
              <li
                className="tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-cursor-pointer"
                onClick={() => handleConditionSelect('is')}
              >
                is
              </li>
              <li
                className="tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-cursor-pointer"
                onClick={() => handleConditionSelect('is not')}
              >
                is not
              </li>
            </ul>
          </div>
        )}
      </div> */}
      
      {/* <div className="tw-flex tw-items-center tw-mb-2">
        <button className="tw-px-3 tw-py-1 tw-rounded-md tw-text-sm tw-bg-white tw-text-blue-600 tw-border tw-border-blue-600">
          AND
        </button>
        <FaChevronDown className="tw-text-blue-600 tw-ml-1" />
      </div> */}
         <div className="tw-mb-2 tw-flex tw-items-center">
        <span className="tw-text-gray-700 tw-mr-2">FILTER BY USER TRAITS / EVENTS</span>
        <span className="tw-text-gray-500">({selectedFilters.length})</span>
        <button 
          className="tw-ml-2 tw-text-blue-600" 
          onClick={() => setShowTraitsModal(true)}
        >
          <FaInfoCircle />
        </button>
      </div>
      
      <div className="tw-flex tw-flex-wrap tw-gap-2 tw-mb-2">
        {selectedFilters.map((filter, index) => (
          <div key={index} className="tw-bg-blue-100 tw-text-blue-800 tw-px-2 tw-py-1 tw-rounded-full tw-text-sm tw-flex tw-items-center">
            {filter.name}
            <button
              onClick={() => handleRemoveFilter(index)}
              className="tw-ml-2 tw-text-blue-800 hover:tw-text-blue-900"
            >
              <FaTimes />
            </button>
          </div>
        ))}
      </div>

      <div className="tw-relative">
        <button
          onClick={handleAddFilter}
          className="tw-px-3 tw-py-1 tw-rounded-full tw-text-sm tw-bg-white tw-text-blue-600 tw-border tw-border-blue-600"
        >
          + Add Filter
        </button>
        {showFilterDropdown && (
          <div className="tw-absolute tw-z-10 tw-mt-1 tw-w-56 tw-bg-white tw-rounded-md tw-shadow-lg">
            <div className="tw-p-2">
              <input
                type="text"
                placeholder="Search filter"
                className="tw-w-full tw-px-3 tw-py-2 tw-border tw-rounded-md"
              />
            </div>
            <ul className="tw-max-h-60 tw-overflow-auto">
              {filterOptions.map((option) => (
                <li
                  key={option}
                  className="tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-cursor-pointer"
                  onClick={() => handleSelectFilter(option)}
                >
                  {option}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {currentFilter && (
        <div className="tw-mt-2 tw-flex tw-items-center">
          <span className="tw-bg-blue-100 tw-text-blue-800 tw-px-2 tw-py-1 tw-rounded-full tw-text-sm">
            {currentFilter.name}
          </span>
          <div className="tw-relative tw-ml-2">
            <button
              onClick={() => setShowConditionDropdown(!showConditionDropdown)}
              className="tw-px-3 tw-py-1 tw-rounded-full tw-text-sm tw-bg-white tw-text-blue-600 tw-border tw-border-blue-600"
            >
              Select condition
            </button>
            {showConditionDropdown && (
              <div className="tw-absolute tw-z-10 tw-mt-1 tw-w-56 tw-bg-white tw-rounded-md tw-shadow-lg">
                <ul>
                  {conditionOptions.map((option) => (
                    <li
                      key={option}
                      className="tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-cursor-pointer"
                      onClick={() => handleSelectCondition(option)}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
        </div>
        <div className="tw-p-4 tw-border-t tw-border-gray-300">
          <label className="tw-flex tw-items-center">
            <input type="checkbox" className="tw-mr-2" />
            <span className="tw-text-sm tw-text-gray-700">Only include customers whose 'WhatsApp Opted' is true</span>
          </label>
        </div>
        <div className="tw-p-4 tw-border-t tw-border-gray-300 tw-bg-gray-50">
          <button className="tw-w-full tw-py-2 tw-bg-gray-200 tw-text-gray-700 tw-rounded-lg tw-font-semibold">
            Apply Filters
          </button>
        </div>
      </div>
      <p className="tw-text-lg tw-font-semibold tw-mb-4">351458 users in your audience</p>
      <div className="tw-overflow-x-auto">
        <table className="tw-w-full tw-border-collapse">
          <thead>
            <tr className="tw-border-b tw-border-gray-300">
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">
                <div className="tw-relative tw-w-8 tw-h-8 tw-rounded-full tw-bg-gray-200 tw-flex tw-items-center tw-justify-center tw-overflow-hidden">
                  <FaUser className="tw-text-gray-600" />
                  <div className="tw-absolute tw-inset-0 tw-flex tw-flex-wrap">
                    {['tw-bg-blue-400', 'tw-bg-blue-400', 'tw-bg-yellow-400', 'tw-bg-red-400', 'tw-bg-purple-400'].map((color, index) => (
                      <div key={index} className={`tw-w-1/2 tw-h-1/2 ${color} tw-opacity-50`}></div>
                    ))}
                  </div>
                </div>
              </th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold"></th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Contact</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Email</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Tags</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Created On</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id} className="tw-border-b tw-border-gray-200 hover:tw-bg-gray-50">
                <td className="tw-py-2 tw-px-4">
                  <div className="tw-relative tw-w-8 tw-h-8 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-group" onClick={() => toggleUserCheck(user.id)}>
                    <div className={`tw-w-full tw-h-full tw-rounded-full ${user.color} group-hover:tw-opacity-0 tw-transition-opacity`}></div>
                    <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity">
                      <div className={`tw-w-5 tw-h-5 tw-border-2 tw-border-gray-400 tw-rounded ${checkedUsers.includes(user.id) ? 'tw-bg-blue-500 tw-border-blue-500' : 'tw-bg-white'} tw-flex tw-items-center tw-justify-center`}>
                        {checkedUsers.includes(user.id) && <FaCheck className="tw-text-white tw-text-xs" />}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="tw-py-2 tw-px-4"></td>
                <td className="tw-py-2 tw-px-4">{user.contact}</td>
                <td className="tw-py-2 tw-px-4">{user.email}</td>
                <td className="tw-py-2 tw-px-4">{user.tags}</td>
                <td className="tw-py-2 tw-px-4">{user.createdOn}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showTagsModal && <TagsInfoModal />}
      {showTraitsModal && <TraitsEventsInfoModal />}
    </div>
  );
};

export default ChooseAudience;