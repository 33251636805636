import React, { useState, useEffect } from 'react';
import { useUserTabs } from '../../components/common/UserTabsContext';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Chat.css'

const Chat = () => {
    return (
        <>
            <div className='chatDiv'>
                <Row>
                    <Col className='box'>
                        {/* <Row>
                            <Col>
                                <div className='red-bg pad-10 white-text'>
                                    <h5>Remember Azma's Health</h5>
                                    <h5>Conditions when you chat:</h5>

                                    <div className='small-text'>Emotional Health - Depression</div>
                                    <div className='small-text'>Skin Problems - Dryness</div>
                                </div>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col>
                                <div className='convo-title mt-0 pt-0'>Chat</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='chat-box-container'>
                                    <div className='chat-box-cover'>
                                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/yh73s/t378l9x3xh.jpg" className='img-fluid' alt="" />
                                        {/* <div><i className="fa fa-exclamation-triangle" aria-hidden="true"></i></div>
                                        <p>&nbsp;</p>
                                        <div>Will be functional in second phase.</div> */}
                                    </div>
                                    {/* <div className='chat-postbox'>
                                        <textarea className='form-control'></textarea>
                                    </div>
                                    <div className='pad-10'>
                                        <Row>
                                            <Col sm={5}>
                                                <select className='form-control'>
                                                    <option>Send Card</option>
                                                </select>
                                            </Col>
                                            <Col>
                                                <i className="fa fa-face-smile" aria-hidden="true"></i>
                                                <i className="fa fa-camera" aria-hidden="true"></i>
                                            </Col>
                                            <Col>

                                            </Col>
                                        </Row>
                                    </div> */}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Chat