import React, { useState } from 'react';
import './TimelineProfile.css'; // Import the CSS file

const TimelineProfile = () => {
  const [profileView, setProfileView] = useState("tab1");
  return (
    <div id="container-timeline">
      <ol className="timeline-profile">
        <li className="call" >
            <time>
              <small>Lorem ipsum dolor sit amet,</small>
              <div className="big-profile">consectetuer adipiscing elit.</div>
            </time>
            <article>
              <p><b>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</b></p>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
            </article>
          </li>

          <li className="call" >
            <time>
              <small>Lorem ipsum dolor sit amet,</small>
              <div className="big-profile">consectetuer adipiscing elit.</div>
            </time>
            <article>
              <div className="tabs">
                <a className={`tab ${profileView === "tab1" ? "active-tab" : ""}`} onClick={() => setProfileView("tab1")}>Tab1</a>
                <a className={`tab ${profileView === "tab2" ? "active-tab" : ""}`} onClick={() => setProfileView("tab2")}>Tab2</a>
                <a className={`tab ${profileView === "tab3" ? "active-tab" : ""}`} onClick={() => setProfileView("tab3")}>Tab3</a>
              </div>
              <div className="">
                <div id="tab1" className={`tab-content ${profileView === "tab1" ? "" : "hide"}`}>
                  <p><b>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</b></p>
                  <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>                       
                </div>
                <div id="tab2" className={`tab-content ${profileView === "tab2" ? "" : "hide"}`}>
                  <p><b>Consectetuer adipiscing elit.</b></p>
                  <p>Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.Lorem ipsum dolor sit amet, consectetuer adipiscing elit. </p>                       
                </div>
                <div id="tab3" className={`tab-content ${profileView === "tab3" ? "" : "hide"}`}>
                  <p><b>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</b></p>
                  <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>                       
                </div>
              </div>
              
            </article>
          </li>
      </ol>
    </div>
  );
};

export default TimelineProfile;
