import React,{useState, useEffect, useRef} from 'react';
import '../ContentMemberTransferPage.css';
import { useParams } from "react-router-dom";
import { fetchFileInsightData, downloadFile, updateFileUploadStatus } from '../../../services/rcm/Service';
import { CustomLoader, ModalBoxButton } from '../../utils/CommonLibrary';
import { useNavigate } from 'react-router-dom';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import MembesAccrossPathwayMonthsBar from '../DashboardGraphsCNP/MembesAccrossPathwayMonthsBar';
import MemberPathwayStatusPie from "../DashboardGraphs/MemberPathwayStatusPie";
import TextBoxGeneric from '../ContentMemberEngagement/TextBoxGeneric';


function RCMFileInsight({uploadId,startProcessNew,updateUploadStatus}) {
    
    var { id } = useParams();
    const navigate = useNavigate();
    const textNotesRef = useRef(null);
    const textRemarksRef = useRef(null);

    const [requestFrom, setRequestFrom] = useState(true);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalClass, setModalClass] = useState('md');
    const [modalBody, setModalBody] = useState('');
    const [showModalButton, setShowModalButton] = useState(false);
    const [showHide, setShowHide] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [approveStatus, setApproveStatus] = useState(false);
    
    const [remarks, setRemarks] = useState('');
    const [submittedNotes, setSubmittedNotes] = useState('');
    const [errors, setErrors] = useState('');
    const [errorsNotes, setErrorsNotes] = useState('');
    const [uploadIdNew, setUploadIdNew] = useState('');

    const [viewData, setViewData ] = useState({'uploaderName': '','uploadTime': '','allCount': '','allFileLink': '','successCount': '','successFileLink': '','failCount': '','failFileLink': '',
        'duplicateCount': '','duplicateFileLink': '','notes': '','approvalRemarks': '' });
    
    const [totalViewData, setTotalViewData ] = useState({'totalAgeMember': '','totalGenderMember': '','totalCityMember': '','totalProgramMember': '','totalConditionMember': '','totalMemberNetwork': '','totalMemberPolicyStatus': '',
            'totalContractMember': '','totalEndDateMember': '','totalMemberType': '','totalFailReason': '' });

    const [seriesGraph, setSeriesGraph ] = useState({
        ageDistribution:[],
        genderMember:[],
        cityGraph:[],
        programMember:[],
        conditionMember:[],
        memberNetwork:[],
        memberPolicyStatus:[],
        contractMember:[],
        endDateMember:[],
        memberType:[],
        failReason:[],
    })

    const handleClose = () =>{
        setShowModal(false);
    }

    useEffect(() => {
        if (!id) {
            id = uploadId;
            setRequestFrom(false);
        }
    }, [id, uploadId]); 
    
    useEffect(() => {
        
        if(id != '' && id !== undefined){
            loadUserData();
        }else{
            setLoading(false);
            setModalBody('Something Went Wrong!Please Try again later')
            setModalTitle('Alert!');
            setShowModalButton(true);
            setShowModal(true);
        }
        
    },[]);

    const loadUserData = async () =>{
        try{
            fetchFileInsightData(id)
            .then((response) => {   
                if(response.data.code == 200){
                    const finalResponse = response.data.data;
                    setShowCancel(finalResponse.showCancel);
                    setShowButton(finalResponse.showButtons);
                    setApproveStatus(finalResponse.approvalStatus);
                    setUploadIdNew(finalResponse.id);
                    setViewData(prevState => ({ ...prevState, uploaderName: finalResponse.uploadedBy, uploadTime: finalResponse.uploadTime, allCount: finalResponse.allCount, allFileLink: finalResponse.all,
                        successCount: finalResponse.successCount, successFileLink: finalResponse.success, failCount: finalResponse.failCount, failFileLink: finalResponse.fail, duplicateCount: finalResponse.duplicateCount, duplicateFileLink: finalResponse.duplicate,
                        notes: finalResponse.notes, approvalRemarks: finalResponse.remarks
                    }));
                    setTotalViewData(prevState => ({ ...prevState, totalAgeMember: finalResponse.totalAgeMember, totalGenderMember: finalResponse.totalGenderMember, totalCityMember: finalResponse.totalCityMember,
                        totalConditionMember: finalResponse.totalConditionMember,totalProgramMember: finalResponse.totalProgramMember,totalMemberNetwork: finalResponse.totalMemberNetwork, totalMemberPolicyStatus: finalResponse.totalMemberPolicyStatus, totalContractMember: finalResponse.totalContractMember,totalEndDateMember: finalResponse.totalEndDateMember, totalMemberType: finalResponse.totalMemberType, totalFailReason: finalResponse.totalFailReason
                    }));
                    setSeriesGraph(prevState => ({ ...prevState, ageDistribution: finalResponse.ageMember, genderMember: finalResponse.genderMember.series.data,cityGraph:finalResponse.cityMember, programMember: finalResponse.programMember.series.data, memberNetwork: finalResponse.memberNetwork.series.data,
                        memberPolicyStatus: finalResponse.memberPolicyStatus.series.data,conditionMember:finalResponse.conditionMember,contractMember:finalResponse.contractMember,endDateMember:finalResponse.endDateMember,
                        memberType:finalResponse.memberType,failReason:finalResponse.failReason
                    }));
                }else{
                    setModalBody(response.data.data.msg)
                    setModalTitle('Alert!');
                    setShowModalButton(true);
                    setShowModal(true);
                }
                setLoading(false);
            });
        }catch(error) {
            console.error('Error=', error);
        }
    }
    

    const handleButtonAction = () => {
        navigate('/approval-reports');
    };
   
    const handleBackButton = () => {
        navigate(-1);
    };

    const downloadFileApi = async (fileType) => {
        downloadFile(fileType,uploadIdNew)
        .then((response) => {
            if(response.data.code == 200){
                window.location.href = response.data.data.fileUrl;
            }            
            }
        );
    }

    const updateApproveStatus = (uniqueUploadId,action) => {
        var remarksValue = "";
        if(action != 'cancelled'){
            remarksValue = textRemarksRef.current.value;
            if(remarksValue == ''){
                setErrors("Please Add Remarks")
                return false;
            }
        }
        
        setErrors("");
        updateFileUploadStatus(action,uniqueUploadId,remarksValue,'checker')
        .then((response) => {
            if(response.data.code == 200){
                navigate('/approval-reports');
            }else{
                setShowHide(true);
                setShowModalButton(true);
                setModalBody(response.data.data.message);
                setModalTitle('Message!');
                setShowModal(true);
            }
        }
        );
    };

    

    const updateUploadStatusNew = (actionType) => {

        var notesValue = "";
        if(actionType == 'submitted'){
            notesValue = textNotesRef.current.value;
            if(notesValue == ''){
                setErrorsNotes("Please Add Uploader Notes")
                return false;
            }
        }

        setErrorsNotes("");
        updateUploadStatus(actionType,uploadId,notesValue)
    }

    return (
    <>
        <section className='ContentMemberTransfer'>
            {requestFrom && <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md" style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                    <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackButton}><i className="bi bi-chevron-left me-1"></i> Back</button>
                    <h1 className="main-title mb-0 ">Data Review & Approval</h1>
                </div>
            </div>
            
            }
            <div className="container-fluid">

            <div className='row align-items-center mb-2'>
            <div className="col-md-6 px-0"><h1 className="main-title mt-2 ">{requestFrom ? "Data Migration" : "Final Review"}
            </h1></div>
                   
                </div>
                <div className="row align-items-center pb-3">
                    <div className='content-ProgramPerformance mb-3 p-2'>
                    
                        <div className='row text-center px-2'>

                        <div className="col-6 px-1">
                            <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                    <h1 className="card-title1" style={{fontSize:"16px", fontWeight:"500"}}>{viewData.uploaderName}</h1>
                                    <h5 className="card-text1">
                                    Uploaded by
                                    </h5>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-6 px-1">
                            <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                    <h1 className="card-title1" style={{fontSize:"16px", fontWeight:"500"}}>{viewData.uploadTime}</h1>
                                    <h5 className="card-text1">
                                    Upload Date
                                    </h5>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        {viewData.successCount > 0 &&
                            <div className={!showCancel || viewData.approvalRemarks ? "col-6 px-1 mt-2" : "col-12 px-1 mt-2"}>
                                <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                    <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                        {!requestFrom ? 
                                                <>
                                                    <TextBoxGeneric placeholder="Include key details about the data (e.g., program, condition) and any related hypotheses or assumptions for future reference." ref={textNotesRef} />
                                                    <h5 className="card-text1">
                                                        Uploader Notes <span className='text-danger'>*</span>
                                                    </h5>
                                                </>
                                                : 
                                                viewData.notes && 
                                                    <>
                                                        <h1 className="card-title1" style={{fontSize:"16px", fontWeight:"400"}}>{viewData.notes}</h1>
                                                        <h5 className="card-text1">
                                                            Uploader Notes
                                                        </h5>
                                                    </> 
                                            }
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        }
                        

                        {showButton ? 
                            !showCancel ? 
                                <>
                                    <div className="col-6 px-1 mt-2">
                                        <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                            <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                <TextBoxGeneric placeholder="Add reasons for approval or rejection here.  (e.g., 'Data accurate' or 'Missing info, resubmit')." ref={textRemarksRef} />
                                                <h5 className="card-text1">
                                                    Approval Remarks <span className='text-danger'>*</span>
                                                </h5>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </> : ''
                            : 
                                <>
                               {viewData.approvalRemarks && 
                                <>
                                <div className="col-6 px-1 mt-2">
                                        <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                            <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                <h1 className="card-title1" style={{fontSize:"16px", fontWeight:"400"}}>{viewData.approvalRemarks}</h1>
                                                                <h5 className="card-text1">
                                                                    Approval Remarks
                                                                </h5>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    </>}
                                </>
                        }


                        </div>

                        <div className='row text-center px-2 mt-2'>

                        <div className="col px-1">
                            <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                    <h1 className="card-title1 d-flex align-items-center">{viewData.allCount} {viewData.allFileLink && <Button className="dropdown-item btns-downloads ms-2" onClick={(e) => downloadFileApi('all')}><i className="bi bi-download"></i></Button> }</h1>
                                    <h5 className="card-text1">
                                    Upload Records
                                    </h5>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1">
                            <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                    <h1 className="card-title1 d-flex align-items-center">{viewData.successCount} {viewData.successFileLink && <Button className="dropdown-item btns-downloads ms-2" onClick={(e) => downloadFileApi('preSuccess')}><i className="bi bi-download"></i></Button> }</h1>
                                    <h5 className="card-text1">
                                    Upload Successful
                                    </h5>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1">
                            <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                    <h1 className="card-title1 d-flex align-items-center">{viewData.failCount}  {viewData.failFileLink && <Button type="button" className="dropdown-item btns-downloads ms-2" onClick={() => downloadFileApi('preFail')}><i className="bi bi-download"></i></Button>}</h1>
                                    <h5 className="card-text1">
                                    Upload Failed
                                    </h5>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1">
                            <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                    <h1 className="card-title1 d-flex align-items-center">{viewData.duplicateCount}  {viewData.duplicateFileLink && <Button className="dropdown-item btns-downloads ms-2" onClick={(e) => downloadFileApi('preDuplicate')}><i className="bi bi-download"></i></Button> }</h1>
                                    <h5 className="card-text1">
                                    Duplicate
                                    </h5>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        </div>
                           
                           
                      
                    </div>

                    {viewData.successCount > 0 && 
                        <div className='container-fluid px-0 mb-4'>
                            <div className="row">
                            <div className="col-md-12"><h1 className="main-title mt-2">Successful Graphs</h1></div>

                                <div className="col-md-4 mb-3">
                                    <div className="dashboard-graph-box mb-3">
                                        <h1 className='dashboard-graph-title'>
                                            <span>Age Group
                                                <OverlayTrigger placement="top" 
                                                    overlay={<Tooltip className="custom-tooltip">Members segmented by age groups.</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </span>
                                            <span className="dashboard-graph-number">{totalViewData.totalAgeMember}</span>
                                        </h1>
                          
                                        {loading ? (
                                                    <></>
                                            ) : (
                                                seriesGraph.ageDistribution.dataValue ? (
                                                    <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.ageDistribution}/> 
                                                ) : (
                                                    <div className="emty-column">
                                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                                    </div>
                                                )
                                            )}
                                    </div>
                                </div>

                                <div className="col-md-4 mb-3">
                                    <div className="dashboard-graph-box mb-3">
                                        <h1 className='dashboard-graph-title'>
                                            <span>Gender Distribution
                                                <OverlayTrigger placement="top" 
                                                    overlay={<Tooltip className="custom-tooltip">Gender wise Member distribution.</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </span>
                                            <span className="dashboard-graph-number">{totalViewData.totalAgeMember}</span>
                                        </h1>
                          
                                        {loading ? (
                                            <></>
                                        ) : (
                                            seriesGraph.genderMember ? (
                                                <MemberPathwayStatusPie series={seriesGraph.genderMember} />
                                            ) : (
                                                <div className="emty-pie">
                                                    <h5 className="emty-title-graph">There are no data to display</h5>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-4 mb-3">
                                    <div className="dashboard-graph-box mb-3">
                                    <h1 className='dashboard-graph-title'>
                                            <span>City Distribution
                                            <OverlayTrigger placement="top" 
                                                overlay={<Tooltip className="custom-tooltip">City wise Member distribution</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                            </span> 
                                            <span className="dashboard-graph-number">{totalViewData.totalCityMember}</span>
                                        </h1>
                                        
                                        {loading ? (
                                            <></>
                                        ) : (
                                            seriesGraph.cityGraph.dataValue ? (
                                                <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.cityGraph}/>
                                            ) : (
                                                <div className="emty-column">
                                                    <h5 className="emty-title-graph">There are no data to display</h5>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-4 mb-3">
                                    <div className="dashboard-graph-box mb-3">
                                        <h1 className='dashboard-graph-title'>
                                            <span>Program Distribution
                                                <OverlayTrigger placement="top" 
                                                    overlay={<Tooltip className="custom-tooltip">Program wise Member distribution.</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </span>
                                            <span className="dashboard-graph-number">{totalViewData.totalProgramMember}</span>
                                        </h1>
                          
                                        {loading ? (
                                            <></>
                                        ) : (
                                            seriesGraph.programMember ? (
                                                <MemberPathwayStatusPie series={seriesGraph.programMember} />
                                            ) : (
                                                <div className="emty-pie">
                                                    <h5 className="emty-title-graph">There are no data to display</h5>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>

                                
                                <div className="col-md-4 mb-3">
                                    <div className="dashboard-graph-box mb-3">
                                        <h1 className='dashboard-graph-title'>
                                            <span>Chronic Conditions Distribution
                                                <OverlayTrigger placement="top" 
                                                    overlay={<Tooltip className="custom-tooltip">Chronic Condition wise Member distribution.</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </span>
                                            <span className="dashboard-graph-number">{totalViewData.totalConditionMember}</span>
                                        </h1>
                          
                                        {loading ? (
                                            <></>
                                        ) : (
                                            seriesGraph.conditionMember.dataValue ? (
                                                <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.conditionMember}/>
                                            ) : (
                                                <div className="emty-column">
                                                    <h5 className="emty-title-graph">There are no data to display</h5>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-4 mb-3">
                                    <div className="dashboard-graph-box mb-3">
                                        <h1 className='dashboard-graph-title'>
                                            <span>Member Type
                                                <OverlayTrigger placement="top" 
                                                    overlay={<Tooltip className="custom-tooltip">Member Type wise Member distribution.</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </span>
                                            <span className="dashboard-graph-number">{totalViewData.totalMemberType}</span>
                                        </h1>
                          
                                        {loading ? (
                                            <></>
                                        ) : (
                                            seriesGraph.memberType.dataValue ? (
                                                <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.memberType}/>
                                            ) : (
                                                <div className="emty-column">
                                                    <h5 className="emty-title-graph">There are no data to display</h5>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <div className="dashboard-graph-box mb-3">
                                        <h1 className='dashboard-graph-title'>
                                            <span>Policy End Date Member
                                                <OverlayTrigger placement="top" 
                                                    overlay={<Tooltip className="custom-tooltip">Policy End Date wise Member distribution.</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </span>
                                            <span className="dashboard-graph-number">{totalViewData.totalEndDateMember}</span>
                                        </h1>
                          
                                        {loading ? (
                                            <></>
                                        ) : (
                                            seriesGraph.endDateMember.dataValue ? (
                                                <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.endDateMember}/>
                                            ) : (
                                                <div className="emty-column">
                                                    <h5 className="emty-title-graph">There are no data to display</h5>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <div className="dashboard-graph-box mb-3">
                                        <h1 className='dashboard-graph-title'>
                                            <span>Policy Status
                                                <OverlayTrigger placement="top" 
                                                    overlay={<Tooltip className="custom-tooltip">Policy Status wise Member distribution.</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </span>
                                            <span className="dashboard-graph-number">{totalViewData.totalMemberPolicyStatus}</span>
                                        </h1>
                          
                                        {loading ? (
                                            <></>
                                        ) : (
                                            seriesGraph.memberPolicyStatus ? (
                                                <MemberPathwayStatusPie series={seriesGraph.memberPolicyStatus} />
                                            ) : (
                                                <div className="emty-pie">
                                                    <h5 className="emty-title-graph">There are no data to display</h5>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <div className="dashboard-graph-box mb-3">
                                        <h1 className='dashboard-graph-title'>
                                            <span>Member Network
                                                <OverlayTrigger placement="top" 
                                                    overlay={<Tooltip className="custom-tooltip">Member Network wise Member distribution.</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </span>
                                            <span className="dashboard-graph-number">{totalViewData.totalMemberNetwork}</span>
                                        </h1>
                          
                                        {loading ? (
                                            <></>
                                        ) : (
                                            seriesGraph.memberNetwork ? (
                                                <MemberPathwayStatusPie series={seriesGraph.memberNetwork} />
                                            ) : (
                                                <div className="emty-pie">
                                                    <h5 className="emty-title-graph">There are no data to display</h5>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>

                                


                                <div className="col-md-6 mb-3">
                                    <div className="dashboard-graph-box mb-3">
                                        <h1 className='dashboard-graph-title'>
                                            <span>Contract Name
                                                <OverlayTrigger placement="top" 
                                                    overlay={<Tooltip className="custom-tooltip">Contract Name wise Member distribution.</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </span>
                                            <span className="dashboard-graph-number">{totalViewData.totalContractMember}</span>
                                        </h1>
                          
                                        {loading ? (
                                            <></>
                                        ) : (
                                            seriesGraph.contractMember.dataValue ? (
                                                <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.contractMember}/>
                                            ) : (
                                                <div className="emty-column">
                                                    <h5 className="emty-title-graph">There are no data to display</h5>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>

                                

                                

                            </div>
                        </div> }


                    {viewData.failCount > 0 || viewData.duplicateCount > 0 ? 
                        <div className='container-fluid px-0  mb-4'>
                        <div className="row">
                        <div className="col-md-12"><h1 className="main-title mt-2">Failure Graphs</h1></div>
                                <div className="col-md-12 mb-3">
                                    <div className="dashboard-graph-box mb-3">
                                        <h1 className='dashboard-graph-title'>
                                            <span>Reasons for failure
                                                <OverlayTrigger placement="top" 
                                                    overlay={<Tooltip className="custom-tooltip">Reasons for failure wise Member distribution.</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </span>
                                            <span className="dashboard-graph-number">{totalViewData.totalFailReason}</span>
                                        </h1>
                          
                                        {loading ? (
                                            <></>
                                        ) : (
                                            seriesGraph.failReason.dataValue ? (
                                                <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.failReason}/>
                                            ) : (
                                                <div className="emty-column">
                                                    <h5 className="emty-title-graph">There are no data to display</h5>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div> : ''}


                        {(approveStatus != 'rejected' && approveStatus != 'approved') && 
                            <div className='container-fluid px-0 mb-4'>
                                <div className="row">
                            
                                    <div className="col-md-12">
                                        <div className='content-ProgramPerformance mb-3 p-2'>
                                        {(errors) && <div className="text-danger">{errors}</div>}
                                        {showButton && 
                                            <div className='col-md-auto px-1 mt-2'>
                                                {!showCancel && <button type="button" className="btn btn-primary btn-primary program-save-btn me-2" onClick={() => updateApproveStatus(id,'inprogress')}>Approve</button> }
                                                {!showCancel && <button type="button" className="btn clear-btn program-save-btn me-2" onClick={() => updateApproveStatus(id,'rejected')}>Reject</button> }
                                                {showCancel && <button type="button" className="btn clear-btn program-save-btn me-2" onClick={() => updateApproveStatus(id,'cancelled')}>Cancel</button> }
                                            </div>
                                        }

                                        {approveStatus == 'pending' && 
                                        <>
                                            {(errorsNotes) && <div className="text-danger ">{errorsNotes}</div>}
                                            {viewData.successCount > 0 && <button type="button" id="startProcess" onClick={() => updateUploadStatusNew('submitted',id)} className="btn btn-success uplaod-file-btn mt-1 me-2">Submit for Approval</button> }
                                            <button type="button" id="startProcess" onClick={() => updateUploadStatusNew('cancelled',id)} className="btn clear-btn uplaod-file-btn mt-1 me-2">Cancel</button>
                                        </>
                                    }
                    </div> 
                    </div>
                    </div>
                    </div> }
                </div>
            </div>

            {showModal?<ModalBoxButton handleClose={handleClose} showModaln={showModal} titlen={modalTitle} bodyn={modalBody} type={modalClass}  button={showModalButton ? handleButtonAction : undefined}  hideButton={showHide}/>:null}
            { loading && <CustomLoader /> }
        </section>

    </>
  );
}

export default RCMFileInsight;
