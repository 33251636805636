import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './CreateNewTemplate.css';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';


function CreateNewTemplate() {
    
    const [html, setHtml] = useState('');

    function onChange(content) {
        setHtml(content);
    }

    const modules = {
        toolbar: {
            container: [
                // [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                // [{size: []}],
                // ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                ['bold', 'italic'],
                // [{'list': 'ordered'}, {'list': 'bullet'}, 
                // {'indent': '-1'}, {'indent': '+1'}],
                // ['link', 'image', 'video'],
                ['clean']                                         
            ],
        }
    };

    const editorStyle = {
        height: '160px'
    };

    const editorWrapperStyle = {
        height: '160px',
        marginBottom: '0px'
    };


    const handleBackClick = () => {
        window.history.back();
      };


    const [selectedTemplate, setSelectedTemplate] = useState('None');

    const handleRadioChange = (event) => {
        setSelectedTemplate(event.target.value);
    };


    // Header Image Upload 
    const [fileName, setFileName] = useState('Choose JPG or PNG file');
    const [imagePreview, setImagePreview] = useState(null);

    const handleFileChange = (event) => {
        const { files } = event.target;
        if (files.length > 0) {
            const file = files[0];
            setFileName(file.name);

            // Create a FileReader to read the file
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setFileName('Choose JPG or PNG file');
            setImagePreview(null);
        }
    };


    // Header Video Upload 
    const [videoName, setVideoName] = useState('Choose MP4 file');
    const [videoPreview, setVideoPreview] = useState(null);

    const handleVideoChange = (event) => {
        const { files } = event.target;
        if (files.length > 0) {
            const file = files[0];
            setVideoName(file.name);

            // Create a VideoReader to read the file
            const reader = new FileReader();
            reader.onloadend = () => {
                setVideoPreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setVideoName('Choose MP4 file');
            setVideoPreview(null);
        }
    };

    // Header Document Upload 
    const [documentName, setDocumentName] = useState('Choose PDF file');
    const [documentPreview, setDocumentPreview] = useState(null);

    const handleDocumentChange = (event) => {
        const { files } = event.target;
        if (files.length > 0) {
            const file = files[0];
            setDocumentName(file.name);

            // Create a VideoReader to read the file
            const reader = new FileReader();
            reader.onloadend = () => {
                setDocumentPreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setDocumentName('Choose PDF file');
            setDocumentPreview(null);
        }
    };

    
    

  return (
    <>
      <section className='CreateNewTemplate '>
        <div className="container-fluid px-0 mb-3">
            <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md" style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                    <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackClick}><i className="bi bi-chevron-left me-1"></i> Back</button>
                    <h1 className="main-title" style={{ margin: '0' }}>Create New Template</h1>
                    </div>
                </div>

                <div className='create-new-template p-4'>
                    <div className="row align-items-center mb-3">
                        <div className="col-md-4 mb-3">
                            <label htmlFor="templateName" className="form-label">Template Name</label>
                            <input type="text" className="form-control input-field-ui" id="templateName" name="templateName" placeholder='Enter Template Name...'   />
                        </div>

                        <div className="col-md-4 mb-3">
                            <label htmlFor="templateCategory" className="form-label">Category</label>
                            <select className="form-select input-field-ui" id="conditionId" name="selectMonth" aria-label="Select Month">
                                <option value="">Choose Category</option>
                                <option value="MARKETING">Marketing</option>
                                <option value="UTILITY">Utility</option>
                                <option value="AUTHENTICATION">Authentication</option>
                            </select>
                        </div>

                        <div className="col-md-4 mb-3">
                            <label htmlFor="templateCategory" className="form-label">Button Type(Optional)</label>
                            <select className="form-select input-field-ui" id="conditionId" name="selectMonth" aria-label="Select Month">
                                <option value="0">None</option>
                                <option value="copycode">Copy code, URL, Quick Replies etc</option>
                            </select>
                        </div>

                    
                    </div>

                    <div className="row align-items-center mb-3">
                        <div className="col-12"> 
                            <h4 className="mb-0 add-new-template-title">Templates(s)</h4>
                        </div>
                    </div>
                    <div className='row align-items-center mb-3'>
                        <div className="col-auto">
                          <a type="button" className="link-primary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover">English</a>
                        </div>
                        <div className="col-2">
                            <select className="form-select input-field-ui add-language" id="" name="selectMonth" aria-label="Select Month">
                                <option value="">+ Add Language</option>
                                <option>English</option>
                                <option>Arabic</option>
                            </select>
                        </div>
                    </div>

                        <div className="row mt-4 mb-3">
                        <div className='col-md-8'>
                            <div className='row align-items-center'>
                                <div className="col-12 mb-4"> 
                                    <h4 className="mb-0 add-new-template-subtitle">Template for English UK Language</h4>
                                </div>

                                <div className="col-12 mb-2"> 
                                            <h4 className="mb-0 add-new-template-subtitle1">Header (Optional)</h4>
                                        </div>
                                <div className="col-12"> <label className="form-check-label pt-1">Add a title, or, select the media type you want to get approved for this template's header</label>
                                </div>

                                <div className='col-12 mt-2'>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            name="templateRadio"
                                            id="templateRadio1"
                                            type="radio"
                                            value="None"
                                            checked={selectedTemplate === 'None'}
                                            onChange={handleRadioChange}
                                        />
                                        <label htmlFor='templateRadio1' className="form-check-label pt-1">None</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            name="templateRadio"
                                            id="templateRadio2"
                                            type="radio"
                                            value="Text"
                                            checked={selectedTemplate === 'Text'}
                                            onChange={handleRadioChange}
                                        />
                                        <label htmlFor='templateRadio2' className="form-check-label pt-1">Text</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            name="templateRadio"
                                            id="templateRadio3"
                                            type="radio"
                                            value="Image"
                                            checked={selectedTemplate === 'Image'}
                                            onChange={handleRadioChange}
                                        />
                                        <label htmlFor='templateRadio3' className="form-check-label pt-1">Image</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            name="templateRadio"
                                            id="templateRadio4"
                                            type="radio"
                                            value="Video"
                                            checked={selectedTemplate === 'Video'}
                                            onChange={handleRadioChange}
                                        />
                                        <label htmlFor='templateRadio4' className="form-check-label pt-1">Video</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            name="templateRadio"
                                            id="templateRadio5"
                                            type="radio"
                                            value="Document"
                                            checked={selectedTemplate === 'Document'}
                                            onChange={handleRadioChange}
                                        />
                                        <label htmlFor='templateRadio5' className="form-check-label pt-1">Document</label>
                                    </div>
                                </div>

                                <div className='col-12 mt-2'>
                                    {selectedTemplate === 'None' && <div id="noneHeader"></div>}

                                    {selectedTemplate === 'Text' && 
                                        <div id="textHeader" className='row'>
                                            <div className='col-12 mb-2' >
                                                <div className='position-relative d-flex align-items-center'>
                                                    <input type="text" class="form-control input-field-ui" placeholder="Enter text"/>
                                                    <div className="limited-inputs">0/60</div>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                            <a type="button" className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"><i className='bx bxs-plus-circle'></i> Add variable</a>
                                            </div>
                                        </div>
                                    }

                                    {selectedTemplate === 'Image' && 
                                        <div id="imageHeader" className='row'>
                                            <div className='col-12 mb-2' >
                                                <div className='d-flex align-items-center'>
                                                    <div className="file-input-container">
                                                        <label htmlFor="fileInput" className="custom-file-label">
                                                            {fileName}
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id="fileInput"
                                                            className="custom-file-input"
                                                            accept=".jpg, .png"
                                                            onChange={handleFileChange}
                                                        />
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className='col-12'>
                                             <label className="form-check-label pt-1">This media file will be sent as a sample to WhatsApp, for approval. At the time of sending the template, you can change the media file, if required. You can also send separate media files for each customer in a campaign.</label>
                                            </div>
                                            
                                            {imagePreview && (
                                                        <div className='col-12 mb-2 mt-3' >
                                                        <div className='row'>
                                                            <div className='col-auto'>
                                                                    <div className="image-preview">
                                                                        <img src={imagePreview} alt="Preview" />
                                                                    </div>
                                                                    </div>
                                                            <div className='col px-0'>
                                                            <button className="btn delete-preview-btn"><i class="bi bi-trash"></i></button>
                                                            </div>
                                                        </div>
                                                        </div>
                                            )}
                                                
                                        </div>
                                    }


                                    {selectedTemplate === 'Video' && 
                                        <div id="videoHeader">
                                            <div className='col-12 mb-2' >
                                                <div className='d-flex align-items-center'>
                                                    <div className="file-input-container">
                                                        <label htmlFor="videoInput" className="custom-file-label">
                                                            {videoName}
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id="videoInput"
                                                            className="custom-file-input"
                                                            accept=""
                                                            onChange={handleVideoChange}
                                                        />
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className='col-12'>
                                             <label className="form-check-label pt-1">This media file will be sent as a sample to WhatsApp, for approval. At the time of sending the template, you can change the media file, if required. You can also send separate media files for each customer in a campaign.</label>
                                            </div>
                                            
                                            {videoPreview && (
                                                        <div className='col-12 mb-2 mt-3' >
                                                            <div className='row'>
                                                                <div className='col-auto'>
                                                                        <div className="image-preview">
                                                                            <img src={videoPreview} alt="Preview" />
                                                                        </div>
                                                                        </div>
                                                                <div className='col px-0'>
                                                                <button className="btn delete-preview-btn"><i class="bi bi-trash"></i></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                            )}
                                        </div>
                                    }


                                    {selectedTemplate === 'Document' && 
                                    <div id="documentHeader">
                                            <div className='col-12 mb-2' >
                                                <div className='d-flex align-items-center'>
                                                    <div className="file-input-container">
                                                        <label htmlFor="documentInput" className="custom-file-label">
                                                            {documentName}
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id="documentInput"
                                                            className="custom-file-input"
                                                            accept=".pdf"
                                                            onChange={handleDocumentChange}
                                                        />
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className='col-12'>
                                             <label className="form-check-label pt-1">This media file will be sent as a sample to WhatsApp, for approval. At the time of sending the template, you can change the media file, if required. You can also send separate media files for each customer in a campaign.</label>
                                            </div>
                                            
                                            {documentPreview && (
                                                        <div className='col-12 mb-2 mt-3' >
                                                            <div className='row'>
                                                                <div className='col-auto'>
                                                                        <div className="image-preview">
                                                                            <img src={documentPreview} alt="Preview" />
                                                                        </div>
                                                                        </div>
                                                                <div className='col px-0'>
                                                                <button className="btn delete-preview-btn"><i class="bi bi-trash"></i></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                            )}
                                    </div>
                                    }
                                </div>



                                </div>


                                    <div className="row align-items-center mt-4">
                                        <div className="col-12 mb-2"> 
                                            <h4 className="mb-0 add-new-template-subtitle1">Body</h4>
                                        </div>
                                        <div className="col-12 mb-3"> 
                                            <label className="form-check-label pt-1">The Whatsapp message in the language you have selected </label>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <div style={editorWrapperStyle} className='position-relative'>
                                                <ReactQuill 
                                                    value={html} 
                                                    onChange={onChange} 
                                                    modules={modules}
                                                    className='form-control input-field-ui p-0'
                                                    placeholder='Text'
                                                    style={editorStyle}
                                                />
                                                <div className="limited-inputs1">0/1000</div>
                                            </div>
                                      </div>


                                    <div className="col-md-12 mb-3"> 
                                    <a type="button" className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"><i className='bx bxs-plus-circle'></i> Add variable</a>
                                    </div>


                                    </div>

                                    <div className="row align-items-center mt-4">
                                        <div className="col-12 mb-2"> 
                                            <h4 className="mb-0 add-new-template-subtitle1">Copy code, URL, Quick Replies etc</h4>
                                        </div>
                                        <div className="col-12 mb-3"> 
                                            <label className="form-check-label pt-1">Create buttons that let customers respond to your message or take action</label>
                                        </div>

                                        <div className='col-md-12 mb-3'>
                                            <div className="copy-code-box">
                                                <div className="row">

                                                    <div className="col-12 mb-4">
                                                        <div className="copy-code-alt-box">
                                                            <h4 className="mb-0 copy-code-title">The total number of buttons from all four types cannot exceed 10. <a type="button" className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Learn More</a></h4>

                                                            <h4 className="mb-0 copy-code-title">3/10</h4>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <div className="form-check">
                                                            <input className="form-check-input form-check-input-ui" type="checkbox" name="AddCouponCode" id="flexCheckChecked1"/>
                                                            <label className="form-check-label mt-1" htmlFor="flexCheckChecked1">Add Coupon Code</label>
                                                        </div>

                                                        <div className='row align-items-center'>
                                                            <div className='col-11 pe-0 mt-2'>
                                                                <div className='position-relative d-flex align-items-center'>
                                                                    <input type="text" className="form-control input-field-ui" id="templateName" name="templateName" placeholder='Enter Coupon Code'   />
                                                                    <div className="limited-inputs">0/25</div>
                                                                </div>
                                                            </div>
                                                            {/* <div className='col-1'>
                                                            <button className='btn text-danger'><i className='bx bxs-x-circle'></i></button>
                                                            </div> */}
                                                        </div>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <div className="form-check mb-2">
                                                            <input className="form-check-input form-check-input-ui" type="checkbox" name="AddWebsiteURL" id="flexCheckChecked2"/>
                                                            <label className="form-check-label mt-1" htmlFor="flexCheckChecked2">Add Website URL</label>
                                                        </div>

                                                        <div className="row align-items-center">
                                                            <div className='col-3'>
                                                                <select className="form-select input-field-ui" id="" name="" aria-label="Select Month">
                                                                    <option value="">Static</option>
                                                                    <option>1</option>
                                                                    <option>2</option>
                                                                </select>
                                                            </div>
                                                            <div className='col-8 px-0'>
                                                            <input type="text" className="form-control input-field-ui" id="templateName" name="templateName" placeholder='Enter url, example https://example.com/test'   />
                                                            </div>
                                                            <div className='col-1'>
                                                            <button className='btn text-danger'><i className='bx bxs-x-circle'></i></button>
                                                            </div>
                                                            <div className='col-11 pe-0 mt-2'>
                                                                <div className='position-relative d-flex align-items-center'>
                                                                    <input type="text" className="form-control input-field-ui" id="templateName" name="templateName" placeholder='Enter text for the button'   />
                                                                    <div className="limited-inputs">0/25</div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12 mt-2'>
                                                            <a type="button" className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"><i className='bx bxs-plus-circle'></i> Add another website URL</a>
                                                            </div>
                                                        </div>

                                                    </div>


                                                    <div className="col-12 mb-4">
                                                        <div className="form-check mb-2">
                                                            <input className="form-check-input form-check-input-ui" type="checkbox" name="AddPhoneNumber" id="flexCheckChecked3"/>
                                                            <label className="form-check-label mt-1" htmlFor="flexCheckChecked3">Add Phone Number</label>
                                                        </div>

                                                        <div className="row align-items-center">
                                                            <div className='col-3'>
                                                                <select className="form-select input-field-ui" id="" name="" aria-label="Select Month">
                                                                    <option value="">Static</option>
                                                                    <option>1</option>
                                                                    <option>2</option>
                                                                </select>
                                                            </div>
                                                            <div className='col-8 px-0'>
                                                                <div className='position-relative d-flex align-items-center'>
                                                                    <input type="text" className="form-control input-field-ui" id="templateName" name="templateName" placeholder='Enter Phone Number'   />
                                                                    <div className="limited-inputs">0/25</div>
                                                                </div>
                                                            </div>
                                                            <div className='col-11 pe-0 mt-2'>
                                                                <div className='position-relative d-flex align-items-center'>
                                                                    <input type="text" className="form-control input-field-ui" id="templateName" name="templateName" placeholder='Enter text for the button'   />
                                                                    <div className="limited-inputs">0/25</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>


                                                    <div className="col-12">
                                                        <div className="form-check mb-2">
                                                            <input className="form-check-input form-check-input-ui" type="checkbox" name="AddWebsiteURL" id="flexCheckChecked2"/>
                                                            <label className="form-check-label mt-1" htmlFor="flexCheckChecked2">Add Quick Replies</label>
                                                        </div>

                                                        <div className="row align-items-center">
                                                            <div className='col-11 pe-0'>
                                                                <div className='position-relative d-flex align-items-center'>
                                                                    <input type="text" className="form-control input-field-ui" id="templateName" name="templateName" placeholder='Enter text for the button'   />
                                                                    <div className="limited-inputs">0/25</div>
                                                                </div>
                                                            </div>
                                                            <div className='col-1'>
                                                            <button className='btn text-danger'><i className='bx bxs-x-circle'></i></button>
                                                            </div>
                                                            <div className='col-12 mt-2'>
                                                            <a type="button" className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"><i className='bx bxs-plus-circle'></i> Add another Quick Reply</a>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    

                            
                        </div>
                        <div className='col-md-4'>
                        <div className='row align-items-center'>
                            <div className="col-12 mb-2"> 
                                <h4 className="mb-0 add-new-template-subtitle text-center">Preview</h4>
                            </div>
                            <div className='col-12'>
                            <div className="iphone">
                                <div className="border">
                                <div className='quate-bg'>
                                    <div className="row">
                                        <div className='col-12'>
                                                <h2 className='quate-title'>Welcome, [Member's First Name]! You’re now enrolled in [Program/Service Name]. Get ready to achieve your health goals with our support. We'll be in touch with more details soon. Any questions? Contact us at [Contact Number].s</h2>
                                                <h3 className='quate-subtitle'>#BeTheForce</h3>
                                                <h3 className='quate-time'>02:23 Pm</h3>
                                                
                                        </div>
                                        
                                        <div className='col-12 text-center px-1'>
                                        <hr className='my-2' />
                                        <a type="button" className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">
                                            <i className='bx bx-link-external'></i> Download App Now</a>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <div className='col-12 text-center'>
                            <button type="submit" className="btn btn-primary btn-primary program-save-btn me-2">Submit</button>
                            <button type="button" className="btn clear-btn" >Clear</button>
                        </div>
                    </div>

                </div>


        </div>
      </section>
    </>
  );
}

export default CreateNewTemplate;
