import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function WATemplateLibrary() {
  // Array of card data with IDs for easy management
  const cards = [
    {
      id: 1,
      imgSrc: 'https://storage.googleapis.com/ksabupatest/2024/09/24/y4b8h/ks3ax183xs.png',
      text: `Welcome, [Member's First Name]! You’re now enrolled in [Program/Service Name]. Get ready to achieve your health goals with our support. We'll be in touch with more details soon. Any questions? Contact us at [Contact Number].`
    },
    {
      id: 2,
      imgSrc: 'https://storage.googleapis.com/ksabupatest/2024/09/24/h8b2j/wlz8y8h48u.png',
      text: `Hi [Member's First Name], congrats on enrolling in [Program/Service Name]! We’re excited to guide you on your wellness journey. Stay tuned for more updates. Questions? Reach out at [Contact Number].`
    },
    {
      id: 3,
      imgSrc: 'https://storage.googleapis.com/ksabupatest/2024/09/24/lsx1y/q45lxh7ege.png',
      text: `Hi [Member's First Name], your appointment with [Care Navigator's Name] is confirmed for [Date] at [Time]. Please be ready to discuss your health goals. If you have questions or need to reschedule, contact us at [Contact Number]. Looking forward to seeing you soon!`
    },
    {
      id: 4,
      imgSrc: '',
      text: `Hello [Member's First Name], your appointment with [Care Navigator's Name] is scheduled for [Date] at [Time]. Please have your health records handy for a more insightful session. Need changes? Contact us at [Contact Number].`
    },
    {
      id: 5,
      imgSrc: '',
      text: `Hi [Member's First Name], this is a reminder for your appointment with [Care Navigator's Name] tomorrow at [Time]. We're excited to see you and support your health journey. If you need to reschedule, call us at [Contact Number].`
    },
    {
      id: 6,
      imgSrc: '',
      text: `Hello [Member's First Name], your appointment with [Care Navigator's Name] is scheduled for [Date] at [Time]. Please have your health records handy for a more insightful session. Need changes? Contact us at [Contact Number].`
    },
    {
      id: 7,
      imgSrc: '',
      text: `Hi [Member's First Name], we hope you enjoyed your recent session with [Care Navigator's Name]. Please take a moment to share your feedback by replying to this message or clicking this link: [Feedback Link]. Your input helps us improve our service.`
    },
    {
      id: 8,
      imgSrc: '',
      text: `Hello [Member's First Name], thank you for choosing us! We’d love to hear your thoughts about your experience with [Care Navigator's Name]. Please reply with your feedback or use this link: [Feedback Link]. Your opinion matters to us!`
    }
    
  ];

  // Set the default active card to the first card
  const [activeCard, setActiveCard] = useState(cards[0].id);

  // Function to handle card click
  const handleCardClick = (cardId) => {
    setActiveCard(cardId);
  };

  return (
    <div className='row p-3'>
      {cards.map(card => (
        <Link
          key={card.id}
          className='col-md-3 mb-4'
          onClick={() => handleCardClick(card.id)}
        >
          <div className={`boxbg-card text-center ${activeCard === card.id ? 'active' : ''}`}>
            {activeCard === card.id && (
              <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/dl4h7/kegeg3csj4.png" className='card-icon-tick' alt="" />
            )}
            <img src={card.imgSrc} className='card-images img-fluid' alt="" />
            <p className='card-text'>
              {card.text}
            </p>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default WATemplateLibrary;
