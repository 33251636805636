import CryptoJS from 'crypto-js';


export const encryptData = (text) => {
    try {
        const key_hex = process.env.REACT_APP_KEY;
        const iv_hex = process.env.REACT_APP_IV;

        const key = CryptoJS.enc.Hex.parse(key_hex);
        const iv = CryptoJS.enc.Hex.parse(iv_hex);

        const encrypted = CryptoJS.AES.encrypt(text, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    } catch (error) {
        console.error("encryptData Exception = ", error);
        return false;
    }
};

export const decryptData = (encryptedText) => {
    const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_KEY);
    const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV);

    const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
};