import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


const BackendDrivenDataTable = (props) => {
    const data = props.data ? props.data : [];
    const totalRecords = props.totalRows ? props.totalRows : 0;
    const columns = props.columns ? props.columns : [];
    const rowsPerPage = props.perPage ? props.perPage : 0;
    const currentPage = props.page ? props.page : 1;
    const setCurrentPage = props.setPage;

    const handleSort = (column, sortDirection) => {
        const arr = column.selector.toString().split(".");
        const fieldKey = arr[1];

        if (fieldKey) {
            props.setSortColumn(fieldKey);
            props.setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        }
    };

    const renderSortIcon = (column) => {
        const arr = column.selector.toString().split(".");
        const fieldKey = arr[1];
        if (props.sortColumn === fieldKey) {
            return props.sortDirection === 'asc' ? <i className="fa fa-sort-asc" aria-hidden="true"></i> : <i className="fa fa-sort-desc" aria-hidden="true"></i>;
        } else {
            return <i className="fa fa-sort" aria-hidden="true"></i>;
        }
    };

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'nowrap',
                verticalAlign: 'middle',
                padding: '4px 20px 4px 3px',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
                verticalAlign: 'middle',
                fontSize: '13px',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
                verticalAlign: 'middle',
                fontSize: '13px',
            },
        },
    };

    const [totalPages, setTotalPages] = useState(0);
    const [currentRecordsRange, setCurrentRecordsRange] = useState('');
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupContent, setPopupContent] = useState('');

    useEffect(() => {
        const pages = Math.ceil(totalRecords / rowsPerPage);
        setTotalPages(pages);
    }, [rowsPerPage, totalRecords]);

    useEffect(() => {
        const start = (currentPage - 1) * rowsPerPage + 1;
        const end = Math.min(currentPage * rowsPerPage, totalRecords);
        if (start > 0 && end > 0) {
            setCurrentRecordsRange(`${start}-${end}`);
        }
    }, [currentPage, rowsPerPage, totalRecords]);

    const goToFirstPage = () => setCurrentPage(1);
    const goToPreviousPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
    const goToNextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));
    const goToLastPage = () => setCurrentPage(totalPages);

    const handleReadMore = (content) => {
        setPopupContent(content);
        setPopupOpen(true);
    };

    const closePopup = () => {
        setPopupOpen(false);
        setPopupContent('');
    };

    return (
        <>
            <div className='table-responsive BackendDrivenDataTable'>
                <table className='table table-striped mb-0'>
                    <thead>
                        {columns.map((column, index) => (
                            <th key={index}
                                onClick={() => column.sortable && handleSort(column, props.sortDirection)}
                                style={{
                                    cursor: column.sortable ? 'pointer' : 'default',
                                    ...customStyles.headCells.style,
                                }}
                            >
                                {column.name}
                                {column.sortable && renderSortIcon(column)}
                            </th>
                        ))}
                    </thead>
                    <tbody>
    {data.map((item, index) => (
        <tr key={index} style={customStyles.rows.style}>
            {columns.map((column, colIndex) => (
                <td key={colIndex} style={customStyles.cells.style}>
                    {column.selector ? (
                        (() => {
                            const content = column.selector(item);
                            if (typeof content === 'string') {
                                const words = content.split(' ');
                                if (words.length > 7) {
                                    return (
                                        <>
                                            {words.slice(0, 7).join(' ')}...
                                            <br />
                                            <Link className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" onClick={() => handleReadMore(content)}>
                                                Read More
                                            </Link>
                                        </>
                                    );
                                }
                                return content; // Display the content directly if it's 10 words or fewer
                            }
                            return content; // Display the content directly if it's not a string
                        })()
                    ) : column.cell ? (
                        column.cell(item)
                    ) : null}
                </td>
            ))}
        </tr>
    ))}
</tbody>
                </table>
            </div>

            {isPopupOpen && (
                <div className="popup-overlay" style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'rgba(0, 0, 0, 0.6)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999,
                }}>
                    <div className="popup-content" style={{
                        background: 'white',
                        padding: '16px',
                        borderRadius: '16px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        width: '30%',
                        display: 'flex',
                        justifyContent: "space-between",
                        alignItems: 'flex-start'
                    }}>
                        <p className="mb-0 pe-3">{popupContent}</p>
                        <button type="button" class="btn-close" onClick={closePopup} aria-label="Close"></button>
                    </div>
                </div>
            )}

            <div className="pagination-container">
                <div className="rows-per-page">
                    <label>Rows per page: </label>
                    <select value={rowsPerPage} onChange={(e) => props.setPerPage(Number(e.target.value))}>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                        <option value={25}>25</option>
                    </select>
                </div>

                <div className="records-info">
                    {currentRecordsRange && totalRecords > 0 && `${currentRecordsRange} of ${totalRecords} records`}
                </div>

                {totalRecords > 0 && (
                    <div className="pagination-controls">
                        <button onClick={goToFirstPage} disabled={currentPage === 1}>
                            <i className='bx bx-first-page'></i>
                        </button>
                        <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                            <i className='bx bx-chevron-left'></i>
                        </button>
                        <button onClick={goToNextPage} disabled={currentPage === totalPages}>
                            <i className='bx bx-chevron-right'></i>
                        </button>
                        <button onClick={goToLastPage} disabled={currentPage === totalPages}>
                            <i className='bx bx-last-page'></i>
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default BackendDrivenDataTable;
