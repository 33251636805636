import React, { useState, useEffect } from 'react';
import "./AddMember.css"
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { fetchMemberPersonalData, saveNewMember, checkMembership } from '../services/Service';
import axios from "axios";
import { useParams } from 'react-router-dom';
import { CustomFieldLevelLoader } from '../common/Utils';
import { Link } from 'react-router-dom';
import { MultiSelect } from 'react-multi-select-component';

const AddMember = ({ status, heading, hideModal }) => {

    const qsParams = useParams();
    const userId = qsParams.memberId;

    const [error, setError] = useState("");
    const [errorNew, setErrorNew] = useState("");
    const [message, setMessage] = useState("");
    const [messageNew, setMessageNew] = useState("");
    const [showModal, setShowModal] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [hideAll, setHideAll] = useState(true);
    const [showButton, setShowButton] = useState(true);
    const [memberType, setMemberType] = useState('');
    const [conditionList, setConditionList] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [formData, setFormData] = useState({
        membershipNo: '',
        memberName: '',
        mainMembershipNo: '',
        email: '',
        mobile: '',
        gender: '',
        dob: '',
        city: '',
        memberNetwork: '',
        contractNo: '',

        contractName: '',
        policyStartDate: '',
        policyEndDate: '',
        policyStatus: '',
        policyTotalMembers: '',
        policyMemberType: '',
        hof: '',
        height: '',
        weight: '',
        careProgram: '',

        chronicConditions: '',
        healthRiskCategory: '',
        healthRiskSegment: '',
        personalData: '',
        surgicalHistory: '',
        medicalHistory: '',
        commonChronicProfile: '',
        associatedChronic: '',
        topProvidersVisits: '',
        topSpecialtiesVisits: '',

        topDoctorsVisits: '',
        topConditionsByICD: '',
        opCostPerClaims: '',
        ipCostPerClaims: '',
        totalYtd: '',
        accomodation: '',
        consultation: '',
        medication: '',
        laboratories: '',
        advancedRadiology: '',

        surgicalProcedures: '',
        advancedServices: '',
        dental: '',
        otherServices: ''
    });

    useEffect(() => {
        if (status == 'view') {
            setDisabled(true);
            fetchMemberData(userId);
        } else {
            setDisabled(false);
            setHideAll(false);
        }
    }, [])

    const hideModalNew = () => {
        setShowModal(false);
        hideModal();
    }

    const fetchMemberData = async (userId) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchMemberPersonalData(userId)
            .then((response) => {
                if (response.data.code === 200) {
                    setFormData({
                        membershipNo: response.data.data.members[0].membershipNo,
                        mainMembershipNo: response.data.data.members[0].mainMembershipNo,
                        memberName: response.data.data.members[0].memberName,
                        email: response.data.data.members[0].email,
                        mobile: response.data.data.members[0].mobile,
                        gender: response.data.data.members[0].gender,
                        dob: response.data.data.members[0].dob,
                        city: response.data.data.members[0].city,
                        memberNetwork: response.data.data.members[0].memberNetwork,
                        city: response.data.data.members[0].city,
                        contractNo: response.data.data.members[0].contractNo,

                        contractName: response.data.data.members[0].contractName,
                        policyStartDate: response.data.data.members[0].policyStartDate,
                        policyEndDate: response.data.data.members[0].policyEndDate,
                        policyStatus: response.data.data.members[0].policyStatus.toLowerCase(),
                        policyTotalMembers: response.data.data.members[0].policyMembersTotal,
                        policyMemberType: response.data.data.members[0].policyMemberType,
                        hof: response.data.data.members[0].isHof,
                        height: response.data.data.members[0].height,
                        weight: response.data.data.members[0].weight,
                        careProgram: response.data.data.members[0].carePrograms,

                        chronicConditions: response.data.data.members[0].chronicConditions,
                        healthRiskCategory: response.data.data.members[0].healthRiskCategory,
                        healthRiskSegment: response.data.data.members[0].healthRiskSegment,
                        personalData: response.data.data.members[0].personalData,
                        surgicalHistory: response.data.data.members[0].surgicalHistory,
                        medicalHistory: response.data.data.members[0].medicalHistory,
                        commonChronicProfile: response.data.data.members[0].commonChronicProfile,
                        associatedChronic: response.data.data.members[0].associatedChronicAdvancedConditions,
                        topProvidersVisits: response.data.data.members[0].topProvidersVisits,
                        topSpecialtiesVisits: response.data.data.members[0].topSpecialtiesVisits,

                        topDoctorsVisits: response.data.data.members[0].topDoctorsVisits,
                        topConditionsByICD: response.data.data.members[0].topConditionsByICD,
                        opCostPerClaims: response.data.data.members[0].opCostPerClaims,
                        ipCostPerClaims: response.data.data.members[0].ipCostPerClaims,
                        totalYtd: response.data.data.members[0].totalYTD,
                        accomodation: response.data.data.members[0].accommodation,
                        consultation: response.data.data.members[0].consultation,
                        medication: response.data.data.members[0].medication,
                        laboratories: response.data.data.members[0].laboratories,
                        advancedRadiology: response.data.data.members[0].advancedRadiology,

                        surgicalProcedures: response.data.data.members[0].surgicalProcedures,
                        advancedServices: response.data.data.members[0].advancedServices,
                        dental: response.data.data.members[0].dental,
                        otherServices: response.data.data.members[0].otherServices
                    });
                } else {
                    // setError(response.data.data.message);
                    setMemberType('new');
                    setDisabled(false);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }


    const handleChange = async (e) => {

        const { name, value } = e.target;

        if (e.target.name == 'membershipNo' || e.target.name == 'mainMembershipNo') {
            const member = e.target.value;
            var regExpMember = /^\+?[\d()]*$/
            if (!regExpMember.test(member)) {
                return;
            }
            if (member.length > 12) {
                return;
            }
        }

        if (e.target.name == 'contractNo') {
            const contract = e.target.value;
            var regExpContract = /^\+?[\d()]*$/
            if (!regExpContract.test(contract)) {
                return;
            }
        }

        if (e.target.name == 'mobile') {
            const mobile = e.target.value;
            var regExpMobile = /^\+?[\d()]*$/
            if (!regExpMobile.test(mobile)) {
                return;
            }
            if (mobile.length > 13) {
                return;
            }
        }

        if (e.target.name == 'height' || e.target.name == 'weight') {
            const height = e.target.value;
            var regExpHeight = /^\d*\.?\d*$/
            if (!regExpHeight.test(height)) {
                return;
            }
            if (height.length > 7) {
                return;
            }
        }

        setMessageNew('');
        setErrorNew('');
        setMessage('');
        setError('');

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleDateChange = (date, type) => {
        const formattedDate = formatDate(date);
        
        setFormData({
            ...formData,
            [type]: formattedDate,
        });
    };

    const formatDate = (date) => {
        if (!date) return null;
        const day = String(date.getDate()).padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'short' }); // Get abbreviated month
        const year = date.getFullYear();
        return `${day}${month},${year}`; 
    };

    const submitDetails = async (e) => {
        if (validateForm()) {
            setShowButton(false);
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            await saveNewMember(formData, memberType)
                .then((response) => {
                    if (response.data.code === 200) {
                        if (response.data.data.status == 1) {
                            setError('');
                            setMessage(response.data.data.message);
                            setTimeout(() => {
                                hideModalNew();
                            }, 1000);
                        } else {
                            let items = response.data.data.message.split(',');
                            setError(items);
                            setShowButton(true);
                            setMessage('');
                        }
                    } else {
                        let items = response.data.data.message.split(',');
                        setError(items);
                        setShowButton(true);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    };

    const isValidDate = (dateString) => {
        return !isNaN(Date.parse(dateString));
    };

    const validateForm = () => {
        if (memberType == 'new') {
            const mandatoryFields = ['membershipNo','mainMembershipNo', 'memberName','mobile', 'gender','dob','city','memberNetwork','contractNo' ,'contractName','policyEndDate','policyStatus','policyMemberType',
                'chronicConditions','healthRiskCategory','healthRiskSegment','personalData','medicalHistory','topSpecialtiesVisits','topDoctorsVisits','topConditionsByICD'];

            for (let field of mandatoryFields) {
                console.log('field',field)
                if ((field === 'dob' || field === 'policyEndDate')) {
                    if (!isValidDate(formData[field])) {
                        const errorMsg = 'Enter all mandatory fields,';
                        let items = errorMsg.split(',');
                        setError(items);
                        return false;
                    }
                } else if (!formData[field].trim()) {

                    const errorMsg = 'Enter all mandatory fields,';
                    let items = errorMsg.split(',');
                    setError(items);
                    return false;
                }
            }
            return true;
        } else {
            return true;
        }

    };

    const checkMemberDetails = async (e) => {
        setErrorNew('');
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }

        if (formData['membershipNo'] == '') {
            setErrorNew('Enter Membership No.');
            return false;
        } else if (formData['membershipNo'].length > 12 || formData['membershipNo'].length < 7) {
            setErrorNew('The length of Membership No. should be between 7 to 12 characters');
            return false;
        } else if (!/^\s*[+-]?\d+(\.\d+)?\s*$/.test(formData['membershipNo'])) {
            setErrorNew('Only digits (0-9) are allowed. Please enter a valid number.');
            return false;
        }
        await checkMembership(formData['membershipNo'])
            .then((response) => {
                if (response.data.code === 200) {
                    if (response.data.data.status == 1) {
                        setDisabled(true);
                        setHideAll(true);
                        fetchMemberData(response.data.data.message);
                        setMemberType('old');
                    } else if (response.data.data.status == 2) {
                        setErrorNew(response.data.data.message);
                    } else {
                        setHideAll(true);
                        setMessageNew(response.data.data.message);
                        setMemberType('new');
                        setConditionList(response.data.data.conditionList);
                    }
                } else {
                    setErrorNew(response.data.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    };

    const [expandedRow, setExpandedRow] = useState(0); // Set initial state to 0 for the first row to be always expanded

    const toggleRowExpansion = (index) => {
        setExpandedRow(prevState => (prevState === index ? null : index));
    };

    const handleChangeService = (selected) => {
        setSelectedOptions(selected);
        const selectedValues = selected.map(option => option.value).join(',');
        setFormData({ ...formData, chronicConditions: selectedValues });
    };

    return (
        <>

            <Modal id="memberProfileDetails" show={showModal} onHide={() => hideModalNew()} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>{heading}</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="row">

                                <div className='col-md-12 mb-3'>
                                    <div className='row'>
                                        <div className='col-5'>
                                            <label className="form-label">Membership No.
                                                {!disabled && <span className='text-danger'>*</span>}
                                            </label>
                                            <input type="text" placeholder={!disabled ? "Enter the membership number" : ""} className='form-control control-input-ui-addmember' disabled={disabled || hideAll} name="membershipNo" value={formData['membershipNo']} onChange={handleChange} />
                                            <div className={`${errorNew != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{errorNew}</div>
                                            <div className={`${messageNew != "" ? "messageDivNew" : "hide"}`} style={{ fontWeight: "400 !important", color: "black !important" }}>{messageNew}</div>
                                        </div>
                                        {!disabled && !hideAll &&
                                            <div className='col-6 mt-4 ps-0'>
                                                <Button variant="primary" type="submit" className="btn btn-primary save-btn btn btn-primary mt-2" style={{ background: "#03335b", border: "1px solid #03335b" }} onClick={(e) => checkMemberDetails()} >Search</Button>
                                            </div>
                                        }
                                    </div>

                                </div>

                                {hideAll &&
                                    <>

                                        <div className='col-md-12 mb-1'>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <Link className={`expandable-title ${expandedRow === 0 ? 'active' : ''}`} onClick={() => toggleRowExpansion(0)}>
                                                        Member Information
                                                    </Link>
                                                </div>
                                                {expandedRow === 0 && (
                                                    <div className='col-md-12 mt-3 px-4'>
                                                        <div className='row'>
                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Main Membership No
                                                                {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Enter the Main Membership No" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="mainMembershipNo" value={formData['mainMembershipNo']} onChange={handleChange} />
                                                            </div>
                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Member Name
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Enter the full name of the member" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="memberName" value={formData['memberName']} onChange={handleChange} />
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Member email
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Enter the email address of the member" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="email" value={formData['email']} onChange={handleChange} />
                                                            </div>

                                                            <div className='col-md-3 mb-3'>
                                                                <label className="form-label">Member Mobile No.
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Enter the mobile number of the member" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="mobile" value={formData['mobile']} onChange={handleChange} />
                                                            </div>

                                                            <div className='col-md-3 mb-3'>
                                                                <label className="form-label">Gender
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <select class="form-control control-input-ui-addmember" disabled={disabled} name="gender" value={formData['gender']} onChange={handleChange}>
                                                                    <option value="">--Select Gender--</option>
                                                                    <option value="M">Male</option>
                                                                    <option value="F">Female</option>
                                                                </select>
                                                            </div>

                                                            <div className='col-md-3 mb-3'>
                                                                <label className="form-label">Date of Birth
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <div className="date-picker-wrapper" style={{ position: 'relative' }}>
                                                                    <DatePicker
                                                                        disabled={disabled}
                                                                        selected={formData['dob']}
                                                                        onChange={(date) => handleDateChange(date, 'dob')}
                                                                        dateFormat="dd MMM, yyyy"
                                                                        placeholderText={!disabled ? "Date of Birth" : ""}
                                                                        maxDate={new Date()}
                                                                        className='form-control control-input-ui-addmember'
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                    />
                                                                    {!disabled &&
                                                                        <i className='bx bx-calendar'
                                                                            style={{
                                                                                position: 'absolute',
                                                                                right: '10px',
                                                                                top: '50%',
                                                                                transform: 'translateY(-50%)',
                                                                                pointerEvents: 'none',
                                                                                fontSize: '20px',
                                                                                color: '#666'
                                                                            }}>
                                                                        </i>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className='col-md-3 mb-3'>
                                                                <label className="form-label">City
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Enter the city of the member" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="city" value={formData['city']} onChange={handleChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>


                                        <div className='col-md-12 mb-1'>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <Link className={`expandable-title ${expandedRow === 1 ? 'active' : ''}`} onClick={() => toggleRowExpansion(1)}>
                                                        Policy Information
                                                    </Link>
                                                </div>

                                                {expandedRow === 1 && (
                                                    <div className='col-md-12 mt-3 px-4'>
                                                        <div className='row'>
                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Member Network
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Member Network" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="memberNetwork" value={formData['memberNetwork']} onChange={handleChange} />
                                                            </div>
                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Contract No.
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Contract No" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="contractNo" value={formData['contractNo']} onChange={handleChange} />
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Contract Name
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Contract Name" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="contractName" value={formData['contractName']} onChange={handleChange} />
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Policy Start Date
                                                                </label>
                                                                <div className="date-picker-wrapper" style={{ position: 'relative' }}>
                                                                    <DatePicker
                                                                        disabled={disabled}
                                                                        selected={formData['policyStartDate']}
                                                                        onChange={(date) => handleDateChange(date, 'policyStartDate')}
                                                                        dateFormat="dd MMM, yyyy"
                                                                        placeholderText={!disabled ? "Policy Start Date" : ""}
                                                                        // minDate={new Date()}
                                                                        className='form-control control-input-ui-addmember'
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                    />
                                                                    {!disabled &&
                                                                        <i className='bx bx-calendar'
                                                                            style={{
                                                                                position: 'absolute',
                                                                                right: '10px',
                                                                                top: '50%',
                                                                                transform: 'translateY(-50%)',
                                                                                pointerEvents: 'none',
                                                                                fontSize: '20px',
                                                                                color: '#666'
                                                                            }}>
                                                                        </i>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Policy End Date
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <div className="date-picker-wrapper" style={{ position: 'relative' }}>
                                                                    <DatePicker
                                                                        disabled={disabled}
                                                                        selected={formData['policyEndDate']}
                                                                        onChange={(date) => handleDateChange(date, 'policyEndDate')}
                                                                        dateFormat="dd MMM, yyyy"
                                                                        placeholderText={!disabled ? "Policy End Date" : ""}
                                                                        minDate={new Date()}
                                                                        className='form-control control-input-ui-addmember'
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                    />
                                                                    {!disabled &&
                                                                        <i className='bx bx-calendar'
                                                                            style={{
                                                                                position: 'absolute',
                                                                                right: '10px',
                                                                                top: '50%',
                                                                                transform: 'translateY(-50%)',
                                                                                pointerEvents: 'none',
                                                                                fontSize: '20px',
                                                                                color: '#666'
                                                                            }}>
                                                                        </i>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Policy Status
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <select class="form-control control-input-ui-addmember" disabled={disabled} name="policyStatus" value={formData['policyStatus']} onChange={handleChange} >
                                                                    <option value="">--Select Policy Status--</option>
                                                                    <option value="active contract">Active Contract</option>
                                                                    <option value="lapsed contract">Lapsed Contract</option>
                                                                </select>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Policy Total Members
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Policy Total Members" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="policyTotalMembers" value={formData['policyTotalMembers']} onChange={handleChange} />
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Policy Member Type
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Policy Member Type" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="policyMemberType" value={formData['policyMemberType']} onChange={handleChange} />
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">HOF
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "HOF" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="hof" value={formData['hof']} onChange={handleChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className='col-md-12 mb-1'>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <Link className={`expandable-title ${expandedRow === 2 ? 'active' : ''}`} onClick={() => toggleRowExpansion(2)}>
                                                        Health Information
                                                    </Link>
                                                </div>

                                                {expandedRow === 2 && (
                                                    <div className='col-md-12 mt-3 px-4'>
                                                        <div className='row'>
                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Height (cms.)
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Height (cms.)" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="height" value={formData['height']} onChange={handleChange} />
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Weight (lbs.)
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Weight (lbs.)" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="weight" value={formData['weight']} onChange={handleChange} />
                                                            </div>

                                                            {/* <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Care Program
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Care Program" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="careProgram" value={formData['careProgram']} onChange={handleChange} />
                                                            </div> */}

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Chronic Conditions
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                {!disabled && <MultiSelect
                                                                    options={conditionList}
                                                                    value={selectedOptions}
                                                                    onChange={handleChangeService}
                                                                    labelledBy="Select"
                                                                    hasSelectAll={true}
                                                                    disableSearch={false}
                                                                    overrideStrings={{
                                                                        selectAll: 'Select All',
                                                                        allItemsAreSelected: 'All items are selected',
                                                                        selectSomeItems: 'Select Conditions',
                                                                    }}
                                                                    className="form-select select-input-ui select-input-ui-select"
                                                                    name="requestedLabTest"
                                                                    id="requestedLabTest"
                                                                /> }
                                                                {disabled && <textarea type="text" rows="4" className='form-control control-input-ui-addmember' disabled={disabled} name="chronicConditions" value={formData['chronicConditions']} onChange={handleChange}></textarea>}
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Health Risk Category
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Health Risk Category" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="healthRiskCategory" value={formData['healthRiskCategory']} onChange={handleChange} />
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Health Risk Segment
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Health Risk Segment" : ""} rows="4" className='form-control control-input-ui-addmember' disabled={disabled} name="healthRiskSegment" value={formData['healthRiskSegment']} onChange={handleChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className='col-md-12 mb-1'>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <Link className={`expandable-title ${expandedRow === 3 ? 'active' : ''}`} onClick={() => toggleRowExpansion(3)}>
                                                        Medical History
                                                    </Link>
                                                </div>

                                                {expandedRow === 3 && (
                                                    <div className='col-md-12 mt-3 px-4'>
                                                        <div className='row'>
                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Personal Data
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <textarea type="text" placeholder={!disabled ? "Personal Data" : ""} rows="4" className='form-control control-input-ui-addmember' disabled={disabled} name="personalData" value={formData['personalData']} onChange={handleChange}></textarea>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Surgical History
                                                                </label>
                                                                <textarea type="text" placeholder={!disabled ? "Surgical History" : ""} rows="4" className='form-control control-input-ui-addmember' disabled={disabled} name="surgicalHistory" value={formData['surgicalHistory']} onChange={handleChange}></textarea>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Medical History
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <textarea type="text" placeholder={!disabled ? "Medical History" : ""} rows="4" className='form-control control-input-ui-addmember' disabled={disabled} name="medicalHistory" value={formData['medicalHistory']} onChange={handleChange} ></textarea>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Common Chronic Profile
                                                                    <span className='text-danger'>*</span>
                                                                </label>
                                                                <textarea type="text" placeholder={!disabled ? "Common Chronic Profile" : ""} rows="4" className='form-control control-input-ui-addmember' disabled={disabled} name="commonChronicProfile" value={formData['commonChronicProfile']} onChange={handleChange} ></textarea>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Associated Chronic/Advanced Conditions
                                                                </label>
                                                                <textarea type="text" placeholder={!disabled ? "Associated Chronic/Advanced Conditions" : ""} rows="4" className='form-control control-input-ui-addmember' disabled={disabled} name="associatedChronic" value={formData['associatedChronic']} onChange={handleChange}></textarea>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className='col-md-12 mb-1'>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <Link className={`expandable-title ${expandedRow === 4 ? 'active' : ''}`} onClick={() => toggleRowExpansion(4)}>
                                                        Cost Information
                                                    </Link>
                                                </div>

                                                {expandedRow === 4 && (
                                                    <div className='col-md-12 mt-3 px-4'>
                                                        <div className='row'>
                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Top Providers Visits
                                                                </label>
                                                                <textarea type="text" placeholder={!disabled ? "Top Providers Visits" : ""} rows="4" className='form-control control-input-ui-addmember' disabled={disabled} name="topProvidersVisits" value={formData['topProvidersVisits']} onChange={handleChange}></textarea>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Top Specialities Visits
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <textarea type="text" placeholder={!disabled ? "Top Specialities Visits" : ""} rows="4" className='form-control control-input-ui-addmember' disabled={disabled} name="topSpecialtiesVisits" value={formData['topSpecialtiesVisits']} onChange={handleChange}></textarea>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Top Doctors Visits
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <textarea type="text" placeholder={!disabled ? "Top Doctors Visits" : ""} rows="4" className='form-control control-input-ui-addmember' disabled={disabled} name="topDoctorsVisits" value={formData['topDoctorsVisits']} onChange={handleChange}></textarea>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Top Conditions By ICD
                                                                    {!disabled && <span className='text-danger'>*</span>}
                                                                </label>
                                                                <textarea type="text" placeholder={!disabled ? "Top Conditions By ICD" : ""} rows="4" className='form-control control-input-ui-addmember' disabled={disabled} name="topConditionsByICD" value={formData['topConditionsByICD']} onChange={handleChange}></textarea>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">OP Cost Per Claims
                                                                </label>
                                                                <textarea type="text" placeholder={!disabled ? "OP Cost Per Claims" : ""} rows="4" className='form-control control-input-ui-addmember' disabled={disabled} name="opCostPerClaims" value={formData['opCostPerClaims']} onChange={handleChange}></textarea>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">IP Cost Per Claims
                                                                </label>
                                                                <textarea type="text" placeholder={!disabled ? "IP Cost Per Claims" : ""} rows="4" className='form-control control-input-ui-addmember' disabled={disabled} name="ipCostPerClaims" value={formData['ipCostPerClaims']} onChange={handleChange}></textarea>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Total YTD
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Total YTD" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="totalYtd" value={formData['totalYtd']} onChange={handleChange}></input>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Accommodation
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Accommodation" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="accomodation" value={formData['accomodation']} onChange={handleChange}></input>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Consultation
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Consultation" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="consultation" value={formData['consultation']} onChange={handleChange}></input>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Medication
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Medication" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="medication" value={formData['medication']} onChange={handleChange}></input>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Laboratories
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Laboratories" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="laboratories" value={formData['laboratories']} onChange={handleChange}></input>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Advanced Radiology
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Advanced Radiology" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="advancedRadiology" value={formData['advancedRadiology']} onChange={handleChange}></input>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Surgical Procedures
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Surgical Procedures" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="surgicalProcedures" value={formData['surgicalProcedures']} onChange={handleChange}></input>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Advanced Services
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Advanced Services" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="advancedServices" value={formData['advancedServices']} onChange={handleChange}></input>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Dental
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Dental" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="dental" value={formData['dental']} onChange={handleChange}></input>
                                                            </div>

                                                            <div className='col-md-4 mb-3'>
                                                                <label className="form-label">Other Services
                                                                </label>
                                                                <input type="text" placeholder={!disabled ? "Other Services" : ""} className='form-control control-input-ui-addmember' disabled={disabled} name="otherServices" value={formData['otherServices']} onChange={handleChange}></input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>



                                        {status == 'add' && <div className='col-md-12 text-center mt-3'>
                                            <div className={`${error != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>
                                                {error != "" && error.map((item, index) => (
                                                    <p key={index} style={{ fontWeight: "400 !important" }}>{item.trim()}</p>
                                                ))}
                                            </div>
                                            <div className={`${message != "" ? "messageDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{message}</div>
                                            {showButton ?
                                                <Button variant="primary" type="submit" className="btn btn-primary save-btn me-3" onClick={(e) => submitDetails()} >Add & Enroll Member</Button>
                                                :
                                                <CustomFieldLevelLoader />
                                            }
                                        </div>}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}

export default AddMember;